.card {
  .aaaem-card {
    overflow: hidden;
    &__body {
      margin-bottom: 10px;

      .aaaem-text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        margin-bottom: 5px;

        b {
          font-family: var(--emu-semantic-font-families-mono);
          line-height: 20px;
          display: inline-block;

          @include mq('medium') {
            margin-top: 10px;
            margin-bottom: 20px;
          }

          sup {
            font-family: var(--emu-semantic-font-families-body);
          }
        }

        sup {
          font-size: 8px;
        }
      }
    }

    &__footer {
      .aaaem-button {
        margin-top: 10px;
      }
    }

    &__title-wrapper,
    &__content-wrapper {
      width: 93%;
      margin: auto;
    }
  }
}
