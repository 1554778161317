@font-face {
  font-family: 'Elysio';
  src: url('./assets/fonts/Elysio/Elysio.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Elysio medium';
  src: url('./assets/fonts/Elysio/Elysio-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Elysio bold';
  src: url('./assets/fonts/Elysio/Elysio-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Elysio light';
  src: url('./assets/fonts/Elysio/Elysio-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Elysio thin';
  src: url('./assets/fonts/Elysio/Elysio-Thin.woff') format('woff');
}
