#home {
  .aaaem-carousel {
    @include mq('medium') {
      position: relative;

      &__indicators {
        position: absolute;
        bottom: 0px;
        left: 45%;
        margin-top: var(
          --emu-component-containers-carousel-indicators-margin-top-wide
        );
        margin-bottom: var(
          --emu-component-containers-carousel-indicators-margin-bottom-wide
        );
      }
    }

    @include mq('large') {
      img {
        height: 604px; // hard-coded to match the design. Live uses the same value
      }
    }
  }

  .home-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--slide-1,
    &--slide-3 {
      transition: opacity 0.4s linear;
      opacity: 0;

      &:not(.active) {
        display: none;
      }

      &.active {
        &.active--show {
          opacity: 1;
        }
      }
    }

    &--slide-3 {
      .aaaem-text {
        b {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    @include mq('medium') {
      flex-direction: row;
      margin-top: var(--emu-common-spacing-small);
    }

    > .card {
      position: relative;

      @include mq('medium') {
        max-width: 50%;
      }

      .aaaem-card {
        &__wrapper {
          padding-left: 10px;
          padding-right: 10px;

          @include mq('medium') {
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('large') {
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        &__body {
          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-none);
          }

          @include mq('large') {
            margin-bottom: 10px;
          }
        }

        &__title-wrapper {
          display: none;
        }

        &__content-wrapper {
          width: 100%;

          .aaaem-title {
            margin-bottom: 5px;
          }

          .aaaem-text p {
            margin-bottom: 10px;
          }
        }

        &__footer {
          @include mq('medium') {
            position: absolute;
            bottom: 15px;
          }
        }
      }

      > div,
      .aaaem-card,
      .aaaem-card__wrapper {
        height: 100%;
      }

      .home-card-before-after {
        display: none;

        @include mq('medium') {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .aem-Grid {
            max-width: 88%;
          }
        }
      }
    }
  }

  .footer-copyright-text ul li {
    &:nth-child(3) {
      display: block;
    }

    &:nth-child(2) {
      display: none;
    }
  }
}

#home {
  .home-card-container {
    &--slide-1,
    &--slide-3 {
      @include aem-editor-view() {
        display: block;
        opacity: 1;
      }
    }
  }
}
