[data-component='modal'] {
  .modal-wrapper,
  .close {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
  }

  .modal-content {
    margin-top: 40%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40%;
    animation-name: modalAnimation;
    animation-duration: 0.5s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

    @include mq('medium') {
      max-width: 390px;
      margin-top: 25%;
      margin-bottom: 30px;
    }

    @include mq('1024px') {
      margin-top: 8%;
      margin-bottom: 30px;
    }
  }

  .close {
    top: 0;
    right: 0;
    width: 75px;
    height: 30px;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }

    span {
      display: none;
    }

    @include mq('medium') {
      width: 85px;
      height: 35px;
    }
  }

  @keyframes modalAnimation {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.js-modal-opened {
  overflow: hidden;
  height: 100vh;
}
