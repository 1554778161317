#welcomekit {
  .hcp-kyb-isi__container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
    padding-bottom: 80px;

    .aaaem-text {
      p,
      span,
      i,
      a,
      h3 {
        margin-bottom: var(--emu-common-spacing-medium);
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1;
        color: var(--emu-semantic-colors-extra-secondary-dark-grey-600);

        sup {
          font-size: 11px;
          top: -4px;
        }
      }

      a {
        text-underline-offset: 5px;
      }

      h3 {
        font-family: var(--emu-semantic-font-families-mono);
        margin-bottom: var(--emu-common-spacing-none);
        line-height: 18px;

        .emphasis {
          margin-bottom: 16px;
          display: block;
        }
      }
    }

    @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      max-width: var(--emu-semantic-sizing-large-tablet);
      margin-right: auto;
      margin-left: auto;
    }
  }
}
