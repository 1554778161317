#safety-page {
  .page {
    &__container {
      .aaaem-text p {
        padding-top: 10px;
        margin-bottom: 10px;
      }

      .warnings {
        p:last-child {
          margin-top: 30px;
        }
      }
    }

    &__table {
      th {
        width: 17%;
        &:nth-child(1) {
          width: 33%;
        }
      }

      td {
        padding-left: 10px;
        .emphasis {
          padding-left: 20px;
        }
      }

      &-heading.aaaem-text {
        margin-bottom: var(--emu-common-spacing-none);
        p {
          margin-bottom: 5px;
        }
      }

      &-container {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @include mq('medium') {
          width: 500px;
        }
      }

      &-footer-text {
        padding-top: var(--emu-common-spacing-none);
      }
    }
  }
}
