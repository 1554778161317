.emu-consent-banner {
    &__settings-cta {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      padding-bottom: 10px;
      padding-top: 10px;
      &:active,
      &:hover {
        color: var(--emu-common-colors-white);
      }
    }
  }