#welcomekit {
  // hiding regular header, footer and ISI
  header,
  footer,
  .cmp-experiencefragment--isi {
    display: none;
  }

  // Showing Header, Footer & ISI of welcome kit page
  .welcomekit-page-root {
    header,
    footer,
    .cmp-experiencefragment--isi {
      display: block;
    }
  }

  [data-component='modal'][data-id='video'] .is-video-loaded video {
    display: block;
  }

  .modal {
    &-content {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-medium);
      max-width: calc(100% - var(--emu-common-spacing-medium));
      margin-left: auto;
      margin-right: auto;
      top: 20%;
      border-radius: var(--emu-common-border-radius-xs);
      position: absolute;

      @include mq('576px') {
        margin-top: 28px;
        margin-bottom: 28px;
        max-width: 500px;
      }
    }

    .close {
      width: auto;
      top: -7px;
      right: 5px;

      span {
        display: block;
        color: var(--emu-common-colors-black);
        font-size: 24px;
        line-height: 1;
        opacity: 0.5;
      }
    }
  }

  // Banner Styles
  .banner--your-kyb-welcomekit {
    border-top: 3px solid var(--emu-semantic-colors-extra-secondary-red-200);
    border-bottom: 6px solid
      var(--emu-semantic-colors-extra-secondary-light-grey-1000);
    position: relative;
    padding-top: 30px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;

    .image {
      display: none;
    }

    .aaaem-text {
      p {
        font-family: var(--emu-semantic-font-families-extra-thin);
        font-size: 17px;
        line-height: 25px;

        b {
          font-family: inherit;
          font-weight: var(--emu-common-font-weight-bold);
          display: inline-block;
        }

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    .banner--text-large {
      h1 {
        font-family: var(--emu-semantic-font-families-extra-light);
        font-size: 46px;
        line-height: 55px;
        margin-bottom: 8px;
      }

      sup {
        font-size: 31px;
        top: -12px;
      }
    }

    .banner--text-medium {
      display: block;
      padding-left: 60px;
      padding-right: 60px;
      margin-bottom: 24px;
      font-family: var(--emu-semantic-font-families-extra-thin);

      p {
        line-height: 37px;
        font-size: 25px;

        sup {
          font-size: 17px;
          left: -1px;
          top: -7px;
        }
      }
    }

    @include mq('medium') {
      overflow: hidden;
      padding-top: 20px;
      padding-left: 10px;

      .image {
        max-width: 536px;
        width: 100%;
        display: block;
        position: absolute;
        top: -185px;
        right: -275px;
      }

      .banner--text-medium {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      .banner--all-text-container {
        width: 72%;
      }
    }

    @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 30px;

      .banner--all-text-container {
        width: 66%;
      }

      .banner--text-medium {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
      }

      .banner--text-normal {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      .image {
        bottom: -40px;
        right: -95px;
        top: auto;
      }
    }
  }

  // Blue Container
  .welcome-kit__contact-container {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;

    @include mq('medium') {
      margin-top: 50px;
    }

    .aaaem-text {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
      line-height: 32px;

      p {
        font-family: var(--emu-semantic-font-families-extra-light);
      }

      @include mq('medium') {
        font-size: 24px;
        line-height: 38px;
      }

      @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
        width: 80%;
      }
    }
  }

  // Section Styles
  .welcome-kit-section {
    &__container {
      margin-bottom: var(--emu-common-spacing-none);

      @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
        max-width: var(--emu-semantic-sizing-large-tablet);
        margin-left: auto;
        margin-right: auto;
      }

      .welcomekit-card-container {
        @include mq('medium') {
          &:not(.welcomekit-card-container--no-border) {
            border-bottom: 2px solid #a7a9ac;
            padding-bottom: 50px;
            margin-bottom: 40px;
          }
        }
      }
    }

    &__heading {
      padding-top: 30px;
      padding-bottom: 15px;

      p {
        font-size: 32px;
        line-height: 38px;
        font-family: var(--emu-semantic-font-families-extra-light);
        padding-left: 10px;
        padding-right: 10px;

        sup {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          top: -10px;
        }

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    &__desc {
      font-size: 17px;
      line-height: 26px;

      p {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 50px;
        font-family: var(--emu-semantic-font-families-extra-thin);
        color: var(--emu-semantic-colors-extra-secondary-dark-grey-600);
        text-shadow: 0px 0px 0px
          var(--emu-semantic-colors-extra-secondary-dark-grey-600);

        @include mq('medium') {
          padding-left: 25px;
          padding-right: 25px;
          line-height: 25px;
          margin-bottom: 20px;
          letter-spacing: -0.25px; // intentional decimals to match the design
        }

        @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
          padding-left: 105px;
          padding-right: 105px;
          letter-spacing: normal;
        }
      }
    }
  }

  // Card Styles
  .card {
    .aaaem-card__title-wrapper {
      display: none;
    }

    .aaaem-card__content-wrapper {
      width: auto;
    }

    .aaaem-image img {
      object-fit: contain;
    }

    .aaaem-card__wrapper .aaaem-container,
    .aaaem-card__footer {
      .bg--primary-blue {
        background-color: var(--emu-semantic-colors-extra-primary-blue);
      }

      .bg--secondary-red {
        background-color: var(--emu-semantic-colors-extra-secondary-red-200);
      }
    }

    .aaaem-card__footer {
      .bg--secondary-red,
      .bg--primary-blue {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 30px;

        @include mq('medium') {
          min-height: 115px;
          align-items: flex-end;
        }
      }

      .button {
        width: 100%;
      }

      .welcomekit-button {
        color: var(--emu-common-colors-white);
        border: var(--emu-common-border-width-thin) solid
          rgba(255, 255, 255, 0.4);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        width: 100%;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        font-family: var(--emu-semantic-font-families-extra-light);
        font-size: 17px;
        line-height: 18px;
        letter-spacing: 3px;
        background-color: var(--emu-semantic-colors-none);
        max-height: 55px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: var(--emu-common-spacing-none);
        text-decoration: none;
        text-align: center;

        .cmp-button__text {
          border-bottom: none;
        }

        &--download {
          > span {
            max-width: 110px;
            display: inline-block;
          }
        }
      }
    }

    .aaaem-card__wrapper {
      padding: var(--emu-common-spacing-none);

      .aaaem-card__body {
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('medium') {
          > .cmp-container {
            .container {
              min-height: 195px;
            }
          }
        }

        .card--top-image {
          height: 250px; // fixed height from live
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 350px;
            max-height: 245px;
            padding-top: 5px;
            padding-right: 20px;
            padding-bottom: 5px;
            padding-left: 20px;

            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
              max-height: 235px;
            }
          }
        }

        .aaaem-text {
          margin-left: 24px;

          @include mq('medium') {
            margin-left: 30px;
          }

          @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
            margin-left: 21px;
          }

          h3 {
            font-family: var(--emu-semantic-font-families-extra-light);
            font-size: var(--emu-common-font-sizes-narrow-xl);
            line-height: 28px;
            letter-spacing: 1px;
            margin-top: 2px;
            margin-bottom: 5px;
          }

          sup {
            font-size: 68%;
            left: -1px;
          }

          li,
          p {
            font-size: 17px;
            line-height: 25px;
          }

          ul {
            padding-left: var(--emu-common-spacing-none);
            list-style-type: none;

            li {
              font-family: var(--emu-semantic-font-families-extra-thin);
              position: relative;
              padding-left: 14px;

              &::before {
                content: '';
                background-color: var(--emu-common-colors-white);
                display: inline-block;
                width: 5px;
                height: 5px;
                border: var(--emu-semantic-border-width-thin) solid
                  var(--emu-common-colors-white);
                margin-right: 5px;
                border-radius: 50%;
                position: absolute;
                top: 10px;
                left: 3px;
              }

              ul {
                li {
                  &::before {
                    height: 1px;
                    width: 7px;
                    background-color: var(--emu-common-colors-white);
                    display: block;
                    left: 5px;
                    top: 12px;
                    border: none;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }

        .container--logo-all-text {
          padding-top: 25px;

          > .aem-Grid {
            width: 95%;
            margin: auto;
            display: flex;

            .container--all-text {
              .aem-Grid {
                display: flex;
                flex-direction: column;
              }
            }

            > div:nth-child(2) {
              width: 90%;
              margin-left: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    .card--icon {
      width: 53px; // intentional widths to match design

      @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
        width: 50px;
      }
    }

    @include mq('medium') {
      .left-card--margin-right {
        margin-right: 6px;
      }

      .aaaem-card__wrapper {
        .aaaem-card__body {
          .container--logo-all-text {
            height: 195px;
          }
        }
      }
    }
  }
}
