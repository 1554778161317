#clinical-results {
  .container--grad-image {
    background-color: var(--emu-common-colors-white);
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-radius: 20px;

    .page__text-small {
      font-size: 13px;
      line-height: 18px;
    }

    .text-heading--grad-improvement {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        margin-bottom: 10px;
        text-align: center;
        font-family: var(--emu-semantic-font-families-heading);
        color: var(--emu-semantic-colors-extra-secondary-dark-grey-200);

        .emphasis {
          color: var(--emu-semantic-colors-extra-primary-blue);
        }
      }
    }

    .container-image--grade-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .container {
        max-width: 88%;

        .text {
          .aaaem-text {
            p {
              text-align: center;
              color: var(--emu-semantic-colors-extra-primary-blue);
            }
          }
        }
      }

      // media query to match design
      @include mq('420px') {
        justify-content: space-between;
        gap: 20px;
        padding-left: 10px;
        padding-right: 10px;

        .container {
          max-width: calc(50% - 10px);
        }
      }

      @include mq('medium') {
        justify-content: center;
        gap: min(20%, 90px); // to match the design

        .container {
          max-width: 312px;
        }
      }
    }

    .text-clinical-trial--image-description {
      p {
        font-family: var(--emu-semantic-font-families-heading);
        .emphasis {
          color: var(--emu-semantic-colors-extra-primary-blue);
        }
        i {
          font-style: normal;
          font-family: var(--emu-semantic-font-families-body);
        }
      }
    }

    .text--number-of-treatments {
      p {
        font-size: 15px;
      }
    }
  }

  .text--subject-details {
    ul {
      padding-left: 25px;

      li {
        color: var(--emu-semantic-colors-extra-secondary-dark-grey-200);
        line-height: 22px;

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        b {
          font-family: var(--emu-semantic-font-families-body);
        }
      }
    }

    @include mq('medium') {
      ul {
        li {
          margin-bottom: 3px;
        }
      }
    }
  }

  .container--bg-blue-sec {
    .aaaem-container {
      padding-top: 25px;
      padding-left: 15px;
      padding-bottom: 25px;
      padding-right: 15px;
      border-radius: 15px;
      margin-top: 20px;
      margin-bottom: 40px;
      min-height: 215px;

      .image {
        margin-top: 10px;
        margin-right: 20px;
      }

      b {
        font-family: var(--emu-semantic-font-families-body);
      }

      &.text-container--improvement-in-self-perception {
        margin-bottom: 20px;

        ul {
          text-align: center;
          padding-left: var(--emu-common-spacing-none);
          max-width: 390px;
          margin-left: auto;
          margin-right: auto;

          li {
            display: inline-block;
            vertical-align: middle;
            &::after {
              content: '';
              vertical-align: middle;
              width: 5px;
              height: 5px;
              background-color: var(--emu-common-colors-white);
              margin-left: 3px;
              margin-right: 3px;
              display: inline-block;
              border-radius: 50%;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .container-image--patient-satisfaction {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-around;

      .image {
        .aaaem-image {
          width: 129px; // needed, as this is being used inside flex
        }
      }

      p {
        text-align: center;
      }

      // media query to match design
      @include mq('420px') {
        flex-wrap: nowrap;
        p {
          text-align: left;
        }
      }
    }

    @include mq('medium') {
      display: flex;
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;

      .aaaem-text {
        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      .container-image--patient-satisfaction {
        margin-right: 20px;

        > div:nth-child(2) {
          max-width: 65%;
        }
      }
    }
  }

  .text--safety-info {
    a {
      color: var(--emu-semantic-colors-extra-secondary-red-100);
    }
  }
}
