.aaaem-carousel {
  &__action {
    top: 30%;
    &-prev {
      left: 0;
      transform: translateX(-100%);
    }

    &-next {
      right: 0;
      transform: translateX(100%);
    }
  }
}
