#best-practice-guide {
  // hiding regular header, footer and ISI
  header,
  footer,
  .cmp-experiencefragment--isi {
    display: none;
  }

  // Showing Header, Footer & ISI of welcome kit page
  .best-practice-guide-inner {
    header,
    footer,
    .cmp-experiencefragment--isi {
      display: block;
    }
  }

  .best-prac {
    &-banner {
      &-container {
        display: flex;
        flex-direction: column-reverse;
        background-color: #9faab0; // used only once. No var
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: 15px;
        margin-right: 15px;

        @include mq('medium') {
          flex-direction: row;
        }

        > .container {
          padding-left: 15px;
          padding-right: 15px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .aaaem-text {
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 10px;

          h1 {
            font-family: var(--emu-semantic-font-families-body);
          }
        }
      }

      &-image {
        margin-bottom: 24px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        .image {
          background-color: var(--emu-common-colors-white);
          transform: rotate3d(0, 0, 1, 2deg);
          box-shadow: 6px 7px 9px 3px rgba(0, 0, 0, 0.29);

          .aaaem-image {
            max-height: 300px;

            img {
              height: auto;
              width: 100%;
              max-height: 300px;
            }
          }
        }
      }
    }

    &-actnow {
      &-content {
        background-color: var(
          --emu-semantic-colors-extra-secondary-light-grey-100
        );
        padding-top: 40px;
        padding-right: 55px;
        padding-bottom: 40px;
        padding-left: 55px;
        border-top-left-radius: 40px;
        border-bottom-right-radius: 40px;
      }

      &-text {
        font-size: 19px;
        line-height: 29px;
        margin-bottom: var(--emu-common-spacing-medium);
        color: #636466; // used only in this file. No Var

        p {
          font-family: var(--emu-semantic-font-families-extra-light);
        }
      }

      &-title {
        font-size: 42px;
        line-height: 51px;
        margin-bottom: var(--emu-common-spacing-small);
        color: #65666b; // used only once. No Var

        h3 {
          font-family: var(--emu-semantic-font-families-body);

          .emphasis {
            font-family: var(--emu-semantic-font-families-mono);
            font-size: 60px;
            line-height: 72px;
          }
        }
      }

      &-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;

        @include mq('medium') {
          flex-direction: row;
        }

        .image,
        .container {
          flex: 1;
          padding-left: 15px;
          padding-right: 15px;
          text-align: center;

          @include mq('medium') {
            margin-bottom: var(--emu-semantic-spacing-none);
            text-align: left;
          }
        }

        .image {
          margin-bottom: 25px;
          padding-left: var(--emu-common-spacing-none);

          @include mq('medium') {
            margin-bottom: 20px;
          }
        }

        .container {
          @include mq('medium') {
            margin-bottom: 10px;
          }
        }

        .aaaem-image {
          background-color: var(--emu-common-colors-white);
          transform: rotate3d(0, 0, 1, 358deg);
          box-shadow: 6px 7px 9px 3px rgba(0, 0, 0, 0.29);
        }
      }
    }

    &-act-title {
      font-size: 71px;
      line-height: 85px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: var(--emu-common-spacing-small);
      color: #636466; // used only in this file. No Var

      h2 {
        font-family: var(--emu-semantic-font-families-extra-light);

        .emphasis {
          font-family: var(--emu-semantic-font-families-heading);
        }
      }
    }

    &-brand {
      &-title {
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: 34px;
        font-family: var(--emu-semantic-font-families-body);
        color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
        padding-top: 11px;
        padding-right: 11px;
        padding-bottom: 11px;
        padding-left: 11px;
        margin-bottom: 10px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        text-align: center;
        background-color: var(--emu-semantic-colors-extra-primary-blue);
        margin-bottom: 10px;
      }

      &-inner-container,
      &-inner-container-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-inner-container-wrapper {
        margin-bottom: 30px;

        @include mq('medium') {
          flex-direction: row;
        }

        > .container {
          flex: 1;
        }
      }

      &-left-container,
      &-right-container {
        background: #e7e7e7; // used only once. No Var
        height: 100%;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        padding-left: 25px;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
        }

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          margin-right: 5px;
          margin-left: 5px;
        }

        .image {
          flex: 1;
        }
      }

      @include mq('medium') {
        &-left-container {
          border-bottom-left-radius: 40px;
        }

        &-right-container {
          border-bottom-right-radius: 40px;
        }
      }
    }

    &-left-image,
    &-right-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    &-left-image {
      max-width: 283px;

      img {
        width: 100%;
      }
    }

    &-right-image {
      max-width: 211px;

      img {
        width: 100%;
      }
    }
  }

  .interest-container {
    padding-top: 40px;
    padding-bottom: 40px;

    > .text {
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .aaaem-text {
        font-size: 20px;
        line-height: 32px;

        p {
          font-family: var(--emu-semantic-font-families-extra-light);
        }

        @include mq('medium') {
          font-size: 24px;
          line-height: 38px;
        }
      }
    }
  }

  .aaaem-text {
    .emphasis {
      text-decoration: underline;
      text-decoration-color: var(--emu-semantic-colors-extra-secondary-red-100);
    }
  }

  .best-prac-buttons-container {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 3px solid #b6c0c1; // Used only once. No Var
    margin-bottom: 24px;
  }

  .button--bg-red-blue,
  .button--text-red-blue,
  .button--text-red-black {
    line-height: var(--emu-semantic-line-heights-narrow-large);
    width: 100%;
    max-width: 280px;
    font-family: var(--emu-semantic-font-families-extra-light);
    letter-spacing: 3px;
    max-height: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--emu-common-spacing-none);
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    font-size: 17px;
    line-height: 20px;
    text-decoration: none;
    display: flex;
    border: var(--emu-common-border-width-thin) solid rgba(255, 255, 255, 0.4);
    border-radius: var(--emu-common-border-radius-xs);
    text-decoration: none;

    > span {
      max-width: 110px;
      display: inline-block;
    }

    &:hover {
      background-color: #0069d9; // Used only once. No Var
      border-color: #0062cc; // Used only once. No Var
      color: var(--emu-common-colors-white);
    }
  }

  .button--text-red-blue {
    border: var(--emu-common-border-width-none);
    border-radius: var(--emu-common-border-radius-none);

    &:hover {
      color: #0056b3; // Used only once. No Var
      text-decoration: underline;
      background-color: var(--emu-semantic-colors-extra-secondary-red-200);
    }
  }

  .button--text-red-black {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-extra-secondary-red-200);
    border-radius: var(--emu-common-border-radius-none);
    background-color: var(--emu-semantic-colors-none);
    color: var(--emu-semantic-colors-extra-secondary-red-200);

    > span {
      max-width: 100%;
    }

    &:hover {
      background-color: var(--emu-semantic-colors-none);
      color: #212529; // Used only once. No Var
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-extra-secondary-red-200);
    }
  }

  .aaaem-button__primary-filled:hover .cmp-button__text {
    border: none;
  }
}
