.hcp-kyb-isi {
  &__container {
    padding-left: 2%; // lifted from current site
    padding-right: 2%; // lifted from current site
    padding-top: 30px;

    @include mq('large') {
      padding-left: 15px;
      padding-right: 15px;
    }

    // break point so that 15px spacing on either sides will be matching according to the design
    @include mq('1054px') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    .aaaem {
      &-title {
        h5 {
          font-size: 15px;
          line-height: 16.5px;
          font-family: var(--emu-semantic-font-families-mono);
        }
      }

      &-text {
        font: var(--emu-semantic-typography-narrow-headings-large);
        color: var(--emu-common-colors-black);

        p {
          margin-bottom: 20px;
        }

        a {
          text-decoration-thickness: var(--emu-common-border-width-medium);
          text-decoration-color: #a2aaad; // used only once, no variable
          text-underline-offset: 5px;
        }

        b {
          font-family: var(--emu-semantic-font-families-body);
          font-weight: var(--emu-common-font-weight-bold);
        }
      }
    }
  }
}
