// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

.hcp-kyb-full-width-container,
.hcp-kyb-full-width-container.aaaem-container {
  width: 100%;
  max-width: none;
  padding: var(--emu-common-spacing-none);
}

.hcp-kyb-custom-container {
  max-width: var(--emu-semantic-sizing-breakpoints-large);
  margin-left: auto;
  margin-right: auto;
}

// background color
.bg--primary-blue {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
}

.bg--secondary-light-grey {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
}

.bg--secondary-red {
  background-color: var(--emu-semantic-colors-extra-secondary-red-200);
}

// text font-family
.text--font-body {
  font-family: var(--emu-semantic-font-families-body);
}

.text--font-heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.text--font-mono {
  font-family: var(--emu-semantic-font-families-mono);
}

//text colors
.text--color-white {
  color: var(--emu-common-colors-white);
}

.text--color-blue {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

.text--color-red {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
}

.text--color-dark-grey {
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-400);
}

.text--color-light-grey {
  color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
}

// border radius is constant across the components. This util class can be used instead of adding it across the different components
.border-radius--regular {
  border-radius: var(--emu-semantic-border-radius-large);
}
