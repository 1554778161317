// common custom made up component's css across different pages

// common interest banner
.interest {
  &-container {
    padding-top: 20px;
    padding-bottom: 20px;
    p {
      font: inherit;
    }
  }

  &-text,
  &-sub-text {
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    line-height: 40px;
  }

  &-text {
    margin-bottom: 13px;
  }
}

// common page level re-usable styles, like header, subheader, subtext, table
.page {
  &__container {
    background-color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
    padding-top: 40px;
    padding-right: var(--emu-common-spacing-large);
    padding-bottom: 40px;
    padding-left: var(--emu-common-spacing-large);

    .aaaem-text {
      b {
        font-family: var(--emu-semantic-font-families-mono);
      }

      p {
        margin-bottom: 10px;
      }
    }
  }

  &__heading {
    margin-top: 10px;
    margin-bottom: 15px;
    font: var(--emu-semantic-typography-narrow-headings-xxxl);
    color: var(--emu-semantic-colors-extra-primary-blue);

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: var(--emu-semantic-font-families-heading);
      font-weight: var(--emu-semantic-font-weight-bold);

      .emphasis {
        color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
      }

      sup {
        top: -10px;
      }
    }
  }

  &__sub-heading.aaaem-text {
    p {
      margin-bottom: var(--emu-semantic-spacing-none);
    }
  }

  &__text-small {
    font: var(--emu-semantic-typography-narrow-headings-medium);
  }

  &__table {
    border-top: var(--emu-common-border-width-thin);
    border-right: var(--emu-common-border-width-thin);
    border-top-style: solid;
    border-right-style: solid;
    border-color: var(--emu-common-colors-grey-500);
    overflow-x: auto;

    table {
      width: 100%;
    }

    tr {
      color: var(--emu-common-colors-black);
    }

    th,
    td {
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
    }

    th {
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: var(--emu-semantic-colors-extra-primary-blue);

      .emphasis {
        color: var(--emu-common-colors-white);
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: 20px;
        font-family: 'Elysio light'; // no variable, used only once
      }

      &:nth-child(2) {
        background-color: var(--emu-semantic-colors-extra-secondary-red-200);
      }
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: var(
        --emu-semantic-colors-extra-secondary-light-grey-200
      );
      border-bottom: 1px solid var(--emu-common-colors-grey-500);
      border-left: 1px solid var(--emu-common-colors-grey-500);

      &:nth-child(1) {
        padding-left: 30px;
        text-align: left;
      }

      &:nth-child(2) {
        background-color: var(
          --emu-semantic-colors-extra-secondary-light-grey-300
        );
      }
    }

    &-heading {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &-footer-text {
      padding-top: 10px;
    }
  }
}

.card-container--three {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .card-container--image {
    .aaaem-card__title {
      display: none;
    }

    .text {
      .text-size--large {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          font-family: var(--emu-semantic-font-families-heading);
          word-break: break-word;
        }

        sup {
          font-size: 75%;
          top: -7px;
        }
      }
      p {
        margin-bottom: 10px;
        .emphasis {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 15px;
          display: block;
          margin-bottom: 10px;

          @include mq('medium') {
            padding-bottom: 40px;
          }
        }
      }
    }

    .aaaem-card__footer {
      .aaaem-container {
        display: flex;
        justify-content: space-between;
      }
    }
    .aaaem-image {
      max-width: 150px;
    }
  }

  @include mq($emu-semantic-sizing-breakpoints-medium) {
    flex-direction: row;
    flex-wrap: wrap;

    > div:nth-child(odd) {
      max-width: 244px;
      height: 205px;
    }

    > div:nth-child(2) {
      height: 205px;
      max-width: 487px;
      max-height: 204px;

      .card-container--image {
        width: 100%;
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    .card-container--image {
      .text {
        p {
          line-height: 15px;
        }
      }

      .aaaem-image {
        position: relative;
        top: 15px;
      }

      .aaaem-card__footer {
        .aaaem-container {
          align-items: flex-end;
        }
      }
    }

    .text {
      .text-size--large,
      .aaaem-text {
        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    .card {
      position: relative;

      > div,
      .aaaem-card,
      .aaaem-card__wrapper {
        height: 100%;
      }

      .aaaem-card {
        &__title-wrapper,
        &__content-wrapper {
          width: 100%;
        }
      }

      footer {
        position: absolute;
        bottom: 15px;
        width: 93%;
      }
    }
  }

  @include mq('large') {
    > div:nth-child(odd) {
      flex: 1;
      max-width: 266px;
    }

    > div:nth-child(2) {
      flex: 2;
    }
  }
}

// highlighting the current page li in header
// specificity to override
.general-header .header__content .header__nav-wrapper nav.header__nav {
  @include mq('medium') {
    ul {
      li.emu-navigation__item--active {
        background-color: var(--emu-semantic-colors-extra-primary-blue);
        > a,
        > span {
          color: var(--emu-common-colors-white);
          > span {
            color: var(--emu-common-colors-white);
          }
        }
      }
    }
  }
}
