.general-header {
  .header {
    max-width: var(--emu-semantic-sizing-breakpoints-large);
    margin-left: auto;
    margin-right: auto;

    .cmp-container-header {
      position: relative;
      padding-bottom: 40px;
    }

    &__logo {
      .aaaem-image {
        max-width: 289px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 12px;
      }

      @include mq('medium') {
        position: relative;
        z-index: 2;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      width: 100%;
      right: 0;
      background-color: var(--emu-semantic-colors-none);

      .button {
        display: flex;
        justify-content: flex-end;

        .aaaem-button {
          background-color: var(--emu-semantic-colors-none);

          img {
            max-width: 21px;
          }
        }

        .header__menu-trigger {
          position: absolute;
          top: 9px;
          right: 25px;
          display: flex;
          justify-content: flex-end;
          padding: var(--emu-common-spacing-none);
        }
      }

      .text {
        .aaaem-text {
          p {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            color: var(--emu-semantic-colors-extra-secondary-light-grey-400);
          }
        }

        .header__disclaimer-text {
          position: absolute;
          top: 107px;
          width: 100%;
          text-align: center;
        }
      }
    }

    @include mq('540px') {
      .cmp-container-header {
        padding-bottom: var(--emu-common-spacing-none);

        .header__content {
          .text {
            .header__disclaimer-text {
              text-align: right;
              top: 60px;
              padding-right: 25px;
            }
          }
        }
      }
    }
  }

  @include mq('medium') {
    .header {
      .cmp-container-header {
        .header__content {
          position: static;

          .text {
            .header__disclaimer-text {
              top: 10px;
              padding-right: 15px;
            }
          }
        }
      }

      &__content {
        .button {
          display: none;
        }
      }

      &__logo {
        .aaaem-image {
          max-width: 260px;
        }
      }
    }
  }

  @include mq('large') {
    .header {
      &__logo {
        .aaaem-image {
          max-width: 289px;
        }
      }

      .cmp-container-header {
        .header__content {
          .text {
            .header__disclaimer-text {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
