#about-smf {
  .about-smf {
    &__container {
      margin-bottom: 7px;

      @include mq('medium') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      .aaaem-text {
        p {
          margin-bottom: 10px;
        }

        ul {
          padding-left: 20px;
          text-indent: -8px;
        }
      }
    }

    &__sub-heading {
      h5 {
        font-size: 18px;
        line-height: 20px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
    }

    &-impact__container {
      margin-top: 20px;
      margin-bottom: 30px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
      background-color: var(--emu-common-colors-white);
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .image {
        max-width: 192px;
        margin-left: 10px;

        @include mq('medium') {
          margin-left: 13px;
        }
      }
    }

    &-rounded__text {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-top: 20px;
      padding-right: 15px;
      padding-bottom: 20px;
      padding-left: 15px;
      color: var(--emu-common-colors-white);
      border-radius: 20px;

      &--mt__md {
        margin-top: 24px;
      }

      // increasing specificity
      &.aaaem-text {
        p {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }
}
