.general-header {
  .header__content {
    .header__nav-wrapper {
      overflow-y: auto;
      position: fixed;
      top: 0;
      border-right: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-grey-600);
      height: 100vh;
      transition: 0.4s linear;
      max-width: 260px;
      transform: translateX(-100%);
      z-index: var(--emu-common-other-z-index-modal);
      padding-left: 28px;
      padding-right: 28px;
      background-color: var(--emu-common-colors-white);

      .button {
        margin: var(--emu-common-spacing-none);
        position: absolute;
        z-index: var(--emu-common-other-z-index-modal);
        right: 15px;
        top: 18px;
        z-index: 700;

        .aaaem-button {
          padding: var(--emu-common-spacing-none);

          img {
            max-width: 17px;
          }
        }
      }

      .text {
        z-index: var(--emu-common-other-z-index-modal);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .emu-navigation__content-wrapper {
        background-color: var(--emu-semantic-colors-none);
      }

      .header__nav {
        display: flex;
        justify-content: center;
        padding-top: 220px;
        height: 100vh;

        .emu-navigation__item {
          > a,
          > a > span {
            > i {
              display: none;
            }

            > b {
              font-weight: inherit;
              font-family: inherit;
            }

            sup {
              line-height: 0;
            }

            @include mq('medium') {
              > i {
                display: inline;
              }

              > b {
                display: none;
              }
            }
          }

          &:nth-child(4) {
            a i {
              font-style: normal;
            }
          }
        }

        .emu-navigation__menu--level-0 {
          max-width: 203px;

          .emu-navigation__item-parent {
            position: relative;
            padding-bottom: var(--emu-common-spacing-none);
            padding-top: var(--emu-common-spacing-none);

            > ul {
              padding-left: 11px;
              @include mq('medium') {
                padding-left: var(--emu-common-sizing-none);
              }
            }

            > a {
              margin-top: 15px;
              margin-bottom: 15px;

              span {
                display: inline-block;
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .emu-navigation__menu--level-1 {
              border-top: var(--emu-common-border-width-thin) solid
                var(--emu-semantic-colors-extra-secondary-light-grey-200);
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-semantic-colors-extra-secondary-light-grey-200);

              @include mq('medium') {
                border-bottom: none;
              }

              a {
                padding-bottom: var(--emu-common-spacing-none);
                padding-top: var(--emu-common-spacing-none);
              }
            }

            // adding arrow
            &::after {
              content: '';
              position: absolute;
              top: 22px;
              right: 5px;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid
                var(--emu-semantic-colors-extra-secondary-light-grey-200);
            }

            &.js-open--mobile {
              background-color: var(--emu-semantic-colors-extra-primary-blue);
              a {
                color: var(--emu-common-colors-white);
              }

              @include mq('medium') {
                background-color: var(--emu-common-colors-white);
                > a,
                > a > span {
                  color: var(--emu-semantic-colors-extra-primary-blue);
                }

                &:hover,
                &.emu-navigation__item--active {
                  background-color: var(
                    --emu-semantic-colors-extra-primary-blue
                  );
                  > a,
                  > a > span {
                    color: var(--emu-common-colors-white);
                  }
                }
              }
            }
          }

          li {
            padding-top: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            padding-left: 11px;

            &:hover {
              background-color: var(--emu-semantic-colors-extra-primary-blue);
              a {
                color: var(--emu-common-colors-white);
              }
            }

            a {
              font-size: var(--emu-semantic-font-sizes-narrow-xl);
              line-height: var(--emu-semantic-line-heights-narrow-large);
              font-family: var(--emu-semantic-font-families-mono);
              width: fit-content;
              background-color: var(--emu-common-colors-transparent);
              color: var(
                --emu-component-lists-navigation-banner-item-text-color-default-light
              );

              @include mq('medium') {
                &:hover {
                  text-decoration: underline;
                  color: var(
                    --emu-component-lists-navigation-banner-item-text-color-hover-light
                  );
                }
              }
            }
          }
        }
      }

      .header__prenav {
        max-width: 203px;
        width: 100%;

        ul {
          padding-left: var(--emu-common-spacing-none);
          margin-top: 20px;
          width: 100%;

          li {
            padding-top: 15px;
            padding-right: 10px;
            padding-bottom: 15px;
            padding-left: 10px;
            list-style: none;
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-semantic-colors-extra-secondary-light-grey-400);

            a {
              font-size: 13px;
              line-height: var(--emu-semantic-line-heights-narrow-large);
              color: var(--emu-semantic-colors-extra-secondary-light-grey-400);
              text-transform: uppercase;
              font-family: var(--emu-semantic-font-families-mono);
              text-decoration: none;

              &:active,
              &:hover {
                color: var(--emu-semantic-colors-extra-primary-blue);
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }

          li:nth-child(3) {
            a {
              color: var(--emu-semantic-colors-extra-primary-blue);
            }
          }
        }
      }

      .emu-navigation__item-parent {
        .emu-navigation__menu--level-1 {
          border: none;

          @include mq('medium') {
            background-color: var(--emu-semantic-colors-surface-dark);
          }

          li {
            padding-right: var(--emu-common-spacing-none);

            a {
              color: var(--emu-semantic-colors-extra-primary-blue);

              @include mq('medium') {
                &:hover {
                  color: var(--emu-common-colors-white);
                  background-color: var(--emu-semantic-colors-surface-dark);
                }
              }
            }
          }
        }
      }
    }

    .js-toggle-on {
      transform: translateX(0);
    }
  }

  @include mq('540px') {
    .header__content {
      .header__nav-wrapper {
        max-width: 300px;
        width: 100%;

        .header__nav {
          padding-top: 205px;

          .emu-navigation__menu--level-0 {
            width: 100%;
            max-width: 240px;
          }
        }

        .header__prenav {
          max-width: 240px;
        }
      }
    }
  }

  @include mq('medium') {
    .header {
      position: relative;

      .header__content {
        .header__nav-wrapper {
          height: auto;
          display: block;
          max-width: 100%;
          border-right: none;
          overflow: visible;
          position: static;
          transition: none;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          transform: none;

          .text {
            background-color: var(--emu-semantic-colors-none);
            display: block;
          }

          .header__prenav {
            position: absolute;
            top: 48px;
            right: 15px;
            max-width: none;
            width: auto;

            ul {
              display: flex;
              margin-top: var(--emu-common-spacing-none);

              li {
                border-bottom: none;
                border-right: var(--emu-common-border-width-thin) solid
                  var(--emu-semantic-colors-actions-primary-dark);
                justify-content: center;
                align-items: center;
                display: flex;
                padding: var(--emu-common-spacing-none);

                &:last-child {
                  border-right: none;
                  a {
                    margin-right: 0;
                  }
                }

                a {
                  white-space: pre;
                  font-size: var(--emu-semantic-font-sizes-wide-medium);
                  line-height: 10px;
                  margin-left: 10px;
                  margin-right: 10px;
                }
              }
            }
          }

          .header__nav {
            padding-top: var(--emu-common-spacing-none);
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            justify-content: flex-start;

            .emu-navigation__menu--level-0 {
              .emu-navigation__item-parent {
                position: static;

                &::after {
                  display: none;
                }
              }

              .emu-navigation__item-parent {
                > a {
                  margin-top: var(--emu-common-spacing-none);
                  margin-bottom: var(--emu-common-spacing-none);

                  span {
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }

                ul {
                  position: absolute;
                  top: 43px;
                  left: auto;
                  width: 210px;
                  border-top: var(--emu-common-border-width-thin) solid
                    var(--emu-common-colors-white);

                  li {
                    padding: var(--emu-common-spacing-none);

                    a {
                      font-size: var(--emu-semantic-font-sizes-wide-large);
                      line-height: var(--emu-semantic-line-heights-wide-large);
                      font-family: var(--emu-semantic-font-families-heading);
                      text-decoration: none;
                      padding-top: 10px;
                      padding-right: 30px;
                      padding-left: 10px;
                      padding-bottom: 10px;
                      width: 100%;
                    }

                    &:hover {
                      background-color: var(--emu-common-colors-white);

                      a {
                        color: var(--emu-semantic-colors-extra-primary-blue);
                      }
                    }
                  }
                }
              }
            }
            ul {
              max-width: 100%;
              display: flex;
              flex-wrap: wrap;

              li {
                display: flex;
                align-items: center;
                padding: var(--emu-common-spacing-none);

                a {
                  padding-top: 11px;
                  padding-right: 32px;
                  padding-bottom: 12px;
                  padding-left: 31px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .emu-navigation__item-parent {
            .emu-navigation__menu--level-1 {
              li {
                a {
                  &:hover {
                    background: none;
                  }
                }
              }
            }

            ul {
              top: 50px;
              z-index: var(--emu-common-other-z-index-modal);
              display: none;

              li {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
              }
            }

            &:hover {
              ul {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  @include mq('865px') {
    .header {
      .cmp-container-header {
        .header__content {
          .header__nav-wrapper {
            .header__nav {
              .emu-navigation__menu--level-0 {
                justify-content: flex-start;
              }
            }

            .header__prenav {
              ul {
                li {
                  &:last-child {
                    a {
                      margin-right: 0;
                    }
                  }

                  a {
                    margin-left: 31px;
                    margin-right: 31px;
                    font-size: 13px;
                    line-height: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include mq('large') {
    .header {
      .cmp-container-header {
        .header__content {
          .header__nav-wrapper {
            .header__prenav {
              right: 0;
            }
          }
        }
      }
    }
  }
}
