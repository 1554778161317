#best-practice-guide {
  .hcp-kyb-isi__container {
    margin-top: 40px;
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-left: 10px;
    margin-right: 10px;

    .aaaem-text {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        margin-bottom: var(--emu-common-spacing-medium);
        b {
            font-weight: var(--emu-semantic-font-weight-bold);
            font-family: var(--emu-semantic-font-families-mono);
            line-height: var(--emu-semantic-line-heights-narrow-large);
          }  
        
        i {
          line-height: var(--emu-semantic-line-heights-narrow-large);
        }
          

        a {
          text-decoration: none;
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-extra-secondary-dark-grey-600);
        }
      }
    }

    @include mq('large') {
      padding-left: 23px;
      padding-right: 23px;
    }
  }
}
