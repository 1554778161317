#about-treatment {
  .about-treatment-page {
    &__subtitle {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &__disclaimer {
      ul {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 20px;
      }
    }

    &__table {
      &-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq('600px') {
          flex-direction: row;
          gap: 0;
          align-items: flex-start;
          > .container {
            width: 44%;
            margin-right: 6%;
          }
        }
      }
    }

    &__image {
      max-width: 307px;
      margin-top: 25px;
      margin-bottom: 35px;
    }
  }

  .page {
    &__table {
      th {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 18%;

        &:nth-child(1) {
          width: 15%;
        }
      }

      td {
        text-align: center;
        padding-left: 10px;
      }

      &-footer-text {
        padding-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: 10px;
      }
    }

    &__container {
      .aaaem-text {
        ul {
          padding-left: 20px;
        }
        a {
          color: var(--emu-semantic-colors-extra-secondary-red-100);
        }
      }
    }
  }
}
