
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #E5E5E5 !default;
$emu-semantic-colors-surface-dark: #007396 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #DC6862 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #DC6862 !default;
$emu-semantic-colors-text-light: #3F3F3F !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-extra-primary-blue: #007396 !default;
$emu-semantic-colors-extra-secondary-red-100: #DC6862 !default;
$emu-semantic-colors-extra-secondary-red-200: #E06963 !default;
$emu-semantic-colors-extra-secondary-light-grey-100: #E5E5E5 !default;
$emu-semantic-colors-extra-secondary-light-grey-200: #C7C8CA !default;
$emu-semantic-colors-extra-secondary-light-grey-300: #BABFC1 !default;
$emu-semantic-colors-extra-secondary-light-grey-400: #A2AAAD !default;
$emu-semantic-colors-extra-secondary-light-grey-500: #A3AAAE !default;
$emu-semantic-colors-extra-secondary-light-grey-600: #B4B4B4 !default;
$emu-semantic-colors-extra-secondary-light-grey-700: #9E9EA0 !default;
$emu-semantic-colors-extra-secondary-light-grey-800: #999999 !default;
$emu-semantic-colors-extra-secondary-light-grey-900: #939393 !default;
$emu-semantic-colors-extra-secondary-light-grey-1000: #dedfe0 !default;
$emu-semantic-colors-extra-secondary-light-grey-1100: #d6dadc !default;
$emu-semantic-colors-extra-secondary-dark-grey-100: #6D6E71 !default;
$emu-semantic-colors-extra-secondary-dark-grey-200: #4C4C4C !default;
$emu-semantic-colors-extra-secondary-dark-grey-300: #474747 !default;
$emu-semantic-colors-extra-secondary-dark-grey-400: #3F3F3F !default;
$emu-semantic-colors-extra-secondary-dark-grey-500: #404040 !default;
$emu-semantic-colors-extra-secondary-dark-grey-600: #231f20 !default;
$emu-semantic-font-weight-bold: 500 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'Elysio' , sans-serif !default;
$emu-semantic-font-families-heading: 'Elysio medium' , sans-serif !default;
$emu-semantic-font-families-mono: 'Elysio bold' , sans-serif !default;
$emu-semantic-font-families-extra-light: 'Elysio light' , sans-serif !default;
$emu-semantic-font-families-extra-thin: 'Elysio thin' , sans-serif !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 0px !default;
$emu-semantic-spacing-vertical: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 16px !default;
$emu-semantic-spacing-one-line-height-narrow: 16px !default;
$emu-semantic-font-sizes-narrow-medium: 11px !default;
$emu-semantic-font-sizes-narrow-large: 14px !default;
$emu-semantic-font-sizes-narrow-xl: 16px !default;
$emu-semantic-font-sizes-narrow-xxl: 21px !default;
$emu-semantic-font-sizes-narrow-xxxl: 28px !default;
$emu-semantic-font-sizes-wide-medium: 11px !default;
$emu-semantic-font-sizes-wide-large: 14px !default;
$emu-semantic-font-sizes-wide-xl: 16px !default;
$emu-semantic-font-sizes-wide-xxl: 21px !default;
$emu-semantic-font-sizes-wide-xxxl: 28px !default;
$emu-semantic-line-heights-narrow-medium: 16px !default;
$emu-semantic-line-heights-narrow-large: 20px !default;
$emu-semantic-line-heights-narrow-xl: 23px !default;
$emu-semantic-line-heights-narrow-xxl: 24px !default;
$emu-semantic-line-heights-narrow-xxxl: 31px !default;
$emu-semantic-line-heights-wide-medium: 16px !default;
$emu-semantic-line-heights-wide-large: 20px !default;
$emu-semantic-line-heights-wide-xl: 23px !default;
$emu-semantic-line-heights-wide-xxl: 24px !default;
$emu-semantic-line-heights-wide-xxxl: 31px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 20px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-large-tablet: 960px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large-tablet: 992px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1280px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-wide: 16px !default;
$emu-semantic-sizing-one-line-height-narrow: 16px !default;
$emu-semantic-typography-narrow-headings-xxxl: 500 28px/31px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 500 21px/24px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 16px/23px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 400 14px/20px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 500 11px/16px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 16px/23px 'Elysio' , sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 500 28px/31px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 500 21px/24px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 16px/23px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-wide-headings-large: 400 14px/20px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 500 11px/16px 'Elysio medium' , sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 16px/23px 'Elysio' , sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'extra': (
        'primary': (
          'blue': $emu-semantic-colors-extra-primary-blue
        ),
        'secondary': (
          'red': (
            '100': $emu-semantic-colors-extra-secondary-red-100,
            '200': $emu-semantic-colors-extra-secondary-red-200
          ),
          'lightGrey': (
            '100': $emu-semantic-colors-extra-secondary-light-grey-100,
            '200': $emu-semantic-colors-extra-secondary-light-grey-200,
            '300': $emu-semantic-colors-extra-secondary-light-grey-300,
            '400': $emu-semantic-colors-extra-secondary-light-grey-400,
            '500': $emu-semantic-colors-extra-secondary-light-grey-500,
            '600': $emu-semantic-colors-extra-secondary-light-grey-600,
            '700': $emu-semantic-colors-extra-secondary-light-grey-700,
            '800': $emu-semantic-colors-extra-secondary-light-grey-800,
            '900': $emu-semantic-colors-extra-secondary-light-grey-900,
            '1000': $emu-semantic-colors-extra-secondary-light-grey-1000,
            '1100': $emu-semantic-colors-extra-secondary-light-grey-1100
          ),
          'darkGrey': (
            '100': $emu-semantic-colors-extra-secondary-dark-grey-100,
            '200': $emu-semantic-colors-extra-secondary-dark-grey-200,
            '300': $emu-semantic-colors-extra-secondary-dark-grey-300,
            '400': $emu-semantic-colors-extra-secondary-dark-grey-400,
            '500': $emu-semantic-colors-extra-secondary-dark-grey-500,
            '600': $emu-semantic-colors-extra-secondary-dark-grey-600
          )
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono,
      'extra': (
        'light': $emu-semantic-font-families-extra-light,
        'thin': $emu-semantic-font-families-extra-thin
      )
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'large-tablet': $emu-semantic-sizing-large-tablet,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'largeTablet': $emu-semantic-sizing-breakpoints-large-tablet,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
