#best-practice-guide {
  .cmp-experiencefragment--footer {
    margin-top: 1%;
    footer {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);

      .footer-link-list {
        ul {
          padding-left: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          margin-bottom: var(--emu-common-spacing-medium);
          display: flex;
          flex-direction: column;

          li {
            margin-bottom: 10px;
            list-style: none;
            margin-top: 10px;

            a {
              font-size: var(--emu-semantic-font-sizes-narrow-xl);
              line-height: var(--emu-semantic-line-heights-narrow-xxl);
              text-decoration: none;
              font-family: var(--emu-semantic-font-families-extra-light);

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .footer__details-container {
        display: flex;
        flex-direction: column;

        .footer__image,
        .footer__bottom {
          padding-left: 15px;
          padding-right: 15px;
        }

        .image {
          width: 100%;
          max-width: 250px;
        }

        .footer__bottom {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
        }
      }

      @include mq('medium') {
        max-width: 100%;

        .footer-link-list {
          ul {
            flex-direction: row;
            justify-content: space-between;
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            margin-bottom: 13px;

            > li:first-child {
              &::before {
                content: '';
              }
            }

            li {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              position: relative;

              &::before {
                content: '|';
                position: absolute;
                left: -12px;
              }
            }
          }
        }

        .footer__details-container {
          flex-direction: row;
          align-items: center;

          .image {
            max-width: 206px;
          }
        }
      }

      @include mq('large') {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 25px;
        padding-bottom: 25px;

        .footer-link-list {
          ul {
            li {
              &::before {
                left: -22px;
              }
            }
          }
        }

        .footer__details-container {
          .image {
            max-width: 236px;
          }
        }
      }
    }
  }
}
