#before-after {
  //add this class to text component - KYBELLA® is an individually tailored* injectable treatment
  .text--description {
    margin-bottom: 10px;
    p,
    ul > li {
      .emphasis {
        font-weight: var(--emu-common-font-weight-bold);
      }
    }

    ul {
      padding-left: 20px;
      margin-bottom: 25px;
    }

    a {
      color: var(--emu-semantic-colors-actions-on-primary-dark);
      text-decoration: underline;
    }
  }

  .text--description-small {
    margin-bottom: 10px;

    p {
      font-size: 11px;
      line-height: 15px;
    }
  }

  #before-after-trigger-carousel {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;

    .image {
      min-width: 50px;
      max-width: 82px;
      cursor: pointer;
    }

    .active {
      .image {
        opacity: 0.5;
      }
    }
  }

  //add this class to carousel component
  .carousel--between-text {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  //add this class to carousel component
  .carousel--before-after {
    display: flex;
    flex-direction: column-reverse;

    > div:nth-child(2) {
      display: none;
    }

    .tns-outer {
      position: relative;

      .tns-ovh {
        height: auto !important; // to overwrite the hight that coming by default
        width: 100%;

        .tns-inner {
          overflow: hidden;
          margin: auto;

          // this is for slides
          .aaaem-carousel__content {
            .carouselslide {
              .aaaem-container {
                .aem-Grid {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;

                  .container {
                    width: 100%;

                    .image {
                      max-width: 224px;
                      margin-left: auto;
                      margin-right: auto;

                      .aaaem-image {
                        border: 3px solid var(--emu-common-colors-white);
                      }
                    }

                    .before-after-text {
                      text-align: center;
                      color: var(--emu-semantic-colors-surface-dark);
                      font-size: 18px;
                      line-height: 1;

                      p {
                        margin-bottom: 3px;
                        margin-top: 3px;
                        font-family: var(--emu-semantic-font-families-mono);
                        @include mq('medium') {
                          max-width: 224px;
                        }
                      }
                    }
                  }

                  // needed here to match the design
                  @include mq('540px') {
                    justify-content: space-between;

                    .container {
                      width: 45%;
                      margin-right: 23px;
                      float: none;
                    }
                  }

                  @include mq('medium') {
                    justify-content: flex-start;
                    padding-left: 30px;

                    .container {
                      width: 224px;
                      margin-right: 15px;

                      .image {
                        margin-left: var(--emu-common-spacing-none);
                        margin-right: var(--emu-common-spacing-none);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &--bottom-description {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      margin-top: 20px;
      margin-bottom: 30px;

      .emphasis {
        color: var(--emu-semantic-colors-surface-dark);
        font-family: var(--emu-semantic-font-families-heading);
      }

      b {
        font-family: var(--emu-semantic-font-families-body);
      }
    }

    .aaaem-image {
      img {
        // height is really needed, as the images from live are of different resolutions. To match the design and to make the before after as same height, this is needed
        // this is a very minor change in the number, about 5-6px, so visually this does not effect the image, but changes the layout to look better
        height: 279px;
      }
    }
  }

  @include mq('medium') {
    .carousel--before-after {
      flex-direction: column;

      .tns-outer {
        .tns-controls {
          position: relative;
          top: 60px;
        }
      }
    }

    .text-container--all {
      padding-right: 16px;
    }
  }

  @include mq('large') {
    .carousel--before-after {
      flex-direction: column;
      margin-right: -15px;
    }

    .text-container--all {
      padding-right: 20px;
    }
  }
}
