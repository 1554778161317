#welcomekit {
  // Header
  .header {
    .cmp-container-header {
      max-width: var(--emu-semantic-sizing-breakpoints-large);
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
        margin-top: 37px;
        margin-bottom: 37px;
      }

      .header__logo {
        width: 66.67%;
        @include mq('570px') {
          width: 41.67%;
        }

        @include mq('medium') {
          width: auto;
          min-width: 25%;
        }
      }

      .header__content {
        margin-left: auto;
        align-self: flex-end;
      }

      .button {
        @include mq('medium') {
          display: none;
        }
      }

      .aaaem-button {
        background-color: transparent;
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-extra-primary-blue);
        border-radius: 5px;
        padding: 4px 12px;
        width: 56px;
      }

      .emu-navigation__content-wrapper {
        top: calc(100% + 20px); // 20px is the margin
        max-height: 0;
        overflow: hidden;
        transition: 0.6s ease;

        @include mq('medium') {
          max-height: none;
        }

        nav {
          background-color: var(
            --emu-semantic-colors-extra-secondary-light-grey-1100
          );

          @include mq('medium') {
            background-color: transparent;
          }

          ul {
            padding-top: 10px;

            @include mq('medium') {
              align-items: flex-end;
            }

            li {
              padding-bottom: 15px;
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-semantic-colors-extra-primary-blue);

              @include mq('medium') {
                border-bottom: none;
                padding-bottom: var(--emu-common-spacing-none);
              }

              a {
                padding-top: 10px;
                border: none;
                color: var(--emu-semantic-colors-extra-primary-blue);
                text-align: center;
                font-family: var(--emu-semantic-font-families-extra-light);
                line-height: 19px;

                &:hover {
                  background-color: var(--emu-common-colors-transparent);
                }

                // br tag is necessary as the items should break exactly at some point
                // live used the same technique
                // navigation list does not have emphasis to look for alternatives. Had to use br
                br {
                  display: none;
                }

                @include mq('medium') {
                  font-size: 10px;
                  line-height: 12px;
                  padding-top: 8px;
                  padding-right: 8px;
                  padding-left: 8px;
                  font-weight: 600;

                  // br tag is necessary as the items should break exactly at some point
                  // live used the same technique
                  // navigation list does not have emphasis to look for alternatives. Had to use br
                  br {
                    display: block;
                  }
                }

                @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
                  font-size: 13px;
                  line-height: 16px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .js-toggle-on {
        .emu-navigation__content-wrapper {
          max-height: 400px;
        }
      }
    }

    .emu-navigation__item--active {
      background-color: var(--emu-semantic-colors-none);
    }
  }
}
