#welcomekit {
  .cmp-experiencefragment--footer {
    footer {
      background-color: var(
        --emu-semantic-colors-extra-secondary-light-grey-500
      );
      padding-top: 20px;
      padding-right: 10px;
      padding-bottom: 20px;
      padding-left: 10px;
      max-width: none;

      @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
        padding-top: 25px;
        padding-right: 40px;
        padding-bottom: 25px;
        padding-left: 40px;
      }

      .image {
        max-width: 33.33%;
        margin-bottom: var(--emu-common-spacing-small);

        img {
          width: 100%;
        }

        @include mq('medium') {
          max-width: 25%;
        }
      }

      .footer {
        &-link-list {
          ul {
            padding-left: var(--emu-common-spacing-none);
            padding-top: var(--emu-common-spacing-small);
            padding-bottom: var(--emu-common-spacing-small);
            margin-bottom: var(--emu-common-spacing-medium);
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mq('medium') {
              padding: var(--emu-common-spacing-none);
              flex-direction: row;
            }
          }

          li {
            list-style: none;
            margin-top: 10px;
            margin-bottom: 10px;

            a {
              text-decoration: none;
              font-family: var(--emu-semantic-font-families-extra-light);
              line-height: 24px;

              &:hover {
                text-decoration: underline;
              }
            }

            @include mq('medium') {
              display: inline-flex;
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);

              &::after {
                content: '|';
                color: var(--emu-common-colors-white);
                display: inline-block;
                margin-top: var(--emu-semantic-spacing-none);
                margin-bottom: var(--emu-semantic-spacing-none);
                margin-right: 10px;
                margin-left: 10px;
              }

              &:last-child {
                &::after {
                  display: none;
                }
              }
            }

            @include mq($emu-semantic-sizing-breakpoints-large-tablet) {
              &::after {
                margin-left: 35px;
                margin-right: 14px;
              }
            }
          }
        }

        &__bottom {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          sup {
            font-size: 14px;
            top: 0;
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
