.aaaem-text {
  b {
    font-weight: var(--emu-semantic-font-weight-bold);
    font-family: var(--emu-semantic-font-families-mono);
  }

  &.text--bolder {
    b {
      font-weight: var(--emu-common-font-weight-bold);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  b,
  i,
  pre,
  sup,
  sub,
  u,
  pre,
  th,
  td,
  span {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  sup,
  sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -5px;
  }

  sub {
    top: 5px;
  }
}
