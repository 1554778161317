#mechanism-of-action {
  .container--mechanism-of-action,
  .container--image-all-text {
    display: flex;
    flex-direction: column;
  }

  .text--mechanism-of-action {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .text--nonhuman-nonanimal {
    ul {
      padding-left: 20px;
    }

    b {
      font-family: var(--emu-semantic-font-families-body);
    }
  }

  .text--pharmacokinetics {
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      &:first-child {
        margin-bottom: var(--emu-common-spacing-none);
      }

      b {
        color: var(--emu-semantic-colors-extra-secondary-dark-grey-500);
      }
    }
  }

  .container--image-moa {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: var(--emu-common-colors-white);
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 15px;
    margin-top: 15px;

    .image {
      .aaaem-image {
        max-width: 146px;
        margin-top: 18px;
        margin-right: 20px;
      }
    }

    .text-container--moa {
      margin-top: 40px;

      .title {
        .aaaem-title {
          text-align: center;
          margin-bottom: 10px;
          h5 {
            line-height: 15px;
          }
        }
      }

      .text {
        margin-bottom: 10px;

        .aaaem-text {
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-extra-secondary-light-grey-600);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-extra-secondary-light-grey-600);
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          padding-top: 15px;
          padding-bottom: 5px;
        }
      }
    }
  }

  // breakpoint to match the design
  @include mq('420px') {
    .container--image-moa {
      display: block;
      overflow: hidden;

      .image,
      .container {
        float: left;
        width: 50%;
      }

      .image {
        width: 42%;
        margin-right: 20px;
        margin-top: 18px;

        .aaaem-image {
          max-width: none;
          margin-right: var(--emu-common-spacing-none);
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }
  }

  // breakpoint to match the design
  @include mq('570px') {
    .container--image-moa {
      .image {
        width: 30%;
      }
    }
  }

  @include mq('medium') {
    .container--image-moa {
      margin-top: var(--emu-common-spacing-none);

      .text-container--moa {
        margin-top: var(--emu-common-spacing-none);
      }

      .image {
        width: 44%;
      }
    }

    .container--image-all-text {
      flex-direction: row;
      justify-content: space-between;

      > div {
        width: 45%;
      }
    }
  }
}
