.cmp-experiencefragment--footer {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-500);

  .hcp-kyb-custom-container {
    max-width: var(--emu-semantic-sizing-breakpoints-large);
    margin-left: auto;
    margin-right: auto;
  }

  footer {
    padding-top: 25px;
    max-width: 96%;
    margin-left: auto;
    margin-right: auto;

    sup {
      font-size: 25px;
      line-height: 20px;
      top: 6px;
      display: inline-block;
      margin-right: 3px;
    }

    .emphasis {
      sup {
        font-size: 15px;
        line-height: 1;
        top: 0;
        display: inline;
        margin-right: var(--emu-common-spacing-none);
      }
    }

    .footer-copyright-text {
      ul {
        list-style: none;
        padding: var(--emu-common-sizing-none);
        li {
          display: none;

          &:nth-child(2) {
            display: block;
          }

          .cq-Editable-dom--container & {
            display: block;
          }
        }
      }
    }

    @include mq('large') {
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      max-width: 100%;
    }

    // break point so that 15px spacing on either sides will be matching according to the design
    @include mq('1054px') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    .footer {
      &__top-links-list {
        ul {
          padding: var(--emu-common-spacing-none);

          @include mq('medium') {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
          }

          @include mq('large') {
            display: block;
          }
        }

        li {
          list-style: none;
          padding-bottom: 20px;
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }

          @include mq('medium') {
            display: flex;
            flex: 1 1 auto;
            justify-content: space-around;

            &::after {
              content: '|';
              color: var(--emu-common-colors-white);
              display: inline-block;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }
          }

          @include mq('large') {
            display: inline-flex;
            &::after {
              margin-right: 23px;
              margin-left: 23px;
            }
          }
        }
      }

      &__bottom-section {
        margin-bottom: 20px;
        .image {
          max-width: 200px;
        }
        .aaaem-text {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          margin-top: 10px;
          margin-bottom: 20px;
          @include mq('medium') {
            margin-top: 20px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
