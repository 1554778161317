@font-face {
  font-family: Elysio;
  src: url("resources/fonts/Elysio.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Elysio medium;
  src: url("resources/fonts/Elysio-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Elysio bold;
  src: url("resources/fonts/Elysio-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Elysio light;
  src: url("resources/fonts/Elysio-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Elysio thin;
  src: url("resources/fonts/Elysio-Thin.woff") format("woff");
}

body {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-400);
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin-top: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: #e5e5e5;
  --emu-semantic-colors-surface-dark: #007396;
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: #dc6862;
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: #dc6862;
  --emu-semantic-colors-text-light: #3f3f3f;
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-extra-primary-blue: #007396;
  --emu-semantic-colors-extra-secondary-red-100: #dc6862;
  --emu-semantic-colors-extra-secondary-red-200: #e06963;
  --emu-semantic-colors-extra-secondary-light-grey-100: #e5e5e5;
  --emu-semantic-colors-extra-secondary-light-grey-200: #c7c8ca;
  --emu-semantic-colors-extra-secondary-light-grey-300: #babfc1;
  --emu-semantic-colors-extra-secondary-light-grey-400: #a2aaad;
  --emu-semantic-colors-extra-secondary-light-grey-500: #a3aaae;
  --emu-semantic-colors-extra-secondary-light-grey-600: #b4b4b4;
  --emu-semantic-colors-extra-secondary-light-grey-700: #9e9ea0;
  --emu-semantic-colors-extra-secondary-light-grey-800: #999;
  --emu-semantic-colors-extra-secondary-light-grey-900: #939393;
  --emu-semantic-colors-extra-secondary-light-grey-1000: #dedfe0;
  --emu-semantic-colors-extra-secondary-light-grey-1100: #d6dadc;
  --emu-semantic-colors-extra-secondary-dark-grey-100: #6d6e71;
  --emu-semantic-colors-extra-secondary-dark-grey-200: #4c4c4c;
  --emu-semantic-colors-extra-secondary-dark-grey-300: #474747;
  --emu-semantic-colors-extra-secondary-dark-grey-400: #3f3f3f;
  --emu-semantic-colors-extra-secondary-dark-grey-500: #404040;
  --emu-semantic-colors-extra-secondary-dark-grey-600: #231f20;
  --emu-semantic-font-weight-bold: 500;
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "Elysio", sans-serif;
  --emu-semantic-font-families-heading: "Elysio medium", sans-serif;
  --emu-semantic-font-families-mono: "Elysio bold", sans-serif;
  --emu-semantic-font-families-extra-light: "Elysio light", sans-serif;
  --emu-semantic-font-families-extra-thin: "Elysio thin", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-font-sizes-narrow-medium: 11px;
  --emu-semantic-font-sizes-narrow-large: 14px;
  --emu-semantic-font-sizes-narrow-xl: 16px;
  --emu-semantic-font-sizes-narrow-xxl: 21px;
  --emu-semantic-font-sizes-narrow-xxxl: 28px;
  --emu-semantic-font-sizes-wide-medium: 11px;
  --emu-semantic-font-sizes-wide-large: 14px;
  --emu-semantic-font-sizes-wide-xl: 16px;
  --emu-semantic-font-sizes-wide-xxl: 21px;
  --emu-semantic-font-sizes-wide-xxxl: 28px;
  --emu-semantic-line-heights-narrow-medium: 16px;
  --emu-semantic-line-heights-narrow-large: 20px;
  --emu-semantic-line-heights-narrow-xl: 23px;
  --emu-semantic-line-heights-narrow-xxl: 24px;
  --emu-semantic-line-heights-narrow-xxxl: 31px;
  --emu-semantic-line-heights-wide-medium: 16px;
  --emu-semantic-line-heights-wide-large: 20px;
  --emu-semantic-line-heights-wide-xl: 23px;
  --emu-semantic-line-heights-wide-xxl: 24px;
  --emu-semantic-line-heights-wide-xxxl: 31px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: 20px;
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-large-tablet: 960px;
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: 15px;
  --emu-component-cards-card-padding-top-wide: 15px;
  --emu-component-cards-card-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-right-wide: 30px;
  --emu-component-cards-card-padding-bottom-narrow: 15px;
  --emu-component-cards-card-padding-bottom-wide: 15px;
  --emu-component-cards-card-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-left-wide: 30px;
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%px;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-extra-secondary-red-100);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-extra-secondary-light-grey-700);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: 200ms;
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-right-narrow: 10px;
  --emu-component-lists-accordion-item-body-padding-right-wide: 10px;
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: 33px;
  --emu-component-lists-accordion-item-body-padding-bottom-wide: 33px;
  --emu-component-lists-accordion-item-body-padding-left-narrow: 60px;
  --emu-component-lists-accordion-item-body-padding-left-wide: 60px;
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-lists-accordion-item-header-padding-top-narrow: 10px;
  --emu-component-lists-accordion-item-header-padding-top-wide: 10px;
  --emu-component-lists-accordion-item-header-padding-right-narrow: 10px;
  --emu-component-lists-accordion-item-header-padding-right-wide: 10px;
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-extra-secondary-light-grey-800);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-extra-secondary-light-grey-800);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-semantic-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-semantic-colors-extra-primary-blue);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-feed-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-feed-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-feed-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-feed-instagram-post-gap-narrow: 40px;
  --emu-component-lists-feed-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-extra-primary-blue);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-mono);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-mono);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-padding-top-narrow: 8px;
  --emu-component-actions-button-padding-top-wide: 8px;
  --emu-component-actions-button-padding-right-narrow: 10px;
  --emu-component-actions-button-padding-right-wide: 10px;
  --emu-component-actions-button-padding-bottom-narrow: 8px;
  --emu-component-actions-button-padding-bottom-wide: 8px;
  --emu-component-actions-button-padding-left-narrow: 10px;
  --emu-component-actions-button-padding-left-wide: 10px;
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-extra-secondary-red-100);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-extra-secondary-red-100);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-extra-secondary-red-100);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-extra-secondary-red-100);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: 15px;
  --emu-component-containers-carousel-indicators-margin-top-wide: 5px;
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: 15px;
  --emu-component-containers-carousel-indicators-margin-bottom-wide: 5px;
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: 14px;
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: 14px;
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-extra-primary-blue);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-extra-primary-blue);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-extra-primary-blue);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 600px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 460px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: 82px;
  --emu-component-containers-carousel-indicators-image-size-wide: 82px;
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: 30px;
  --emu-component-containers-carousel-controls-padding-bottom-wide: 30px;
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: #00000040;
  --emu-component-containers-carousel-controls-icon-color-fill-dark: #00000040;
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 340px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-grey-200);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  text-align: left;
  margin: 0;
  padding: 0;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--project-theme-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.hcp-kyb-full-width-container, .hcp-kyb-full-width-container.aaaem-container {
  width: 100%;
  max-width: none;
  padding: var(--emu-common-spacing-none);
}

.hcp-kyb-custom-container {
  max-width: var(--emu-semantic-sizing-breakpoints-large);
  margin-left: auto;
  margin-right: auto;
}

.bg--primary-blue {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
}

.bg--secondary-light-grey {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
}

.bg--secondary-red {
  background-color: var(--emu-semantic-colors-extra-secondary-red-200);
}

.text--font-body {
  font-family: var(--emu-semantic-font-families-body);
}

.text--font-heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.text--font-mono {
  font-family: var(--emu-semantic-font-families-mono);
}

.text--color-white {
  color: var(--emu-common-colors-white);
}

.text--color-blue {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

.text--color-red {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
}

.text--color-dark-grey {
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-400);
}

.text--color-light-grey {
  color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
}

.border-radius--regular {
  border-radius: var(--emu-semantic-border-radius-large);
}

.interest-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.interest-container p {
  font: inherit;
}

.interest-text, .interest-sub-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: 40px;
}

.interest-text {
  margin-bottom: 13px;
}

.page__container {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
  padding-top: 40px;
  padding-right: var(--emu-common-spacing-large);
  padding-bottom: 40px;
  padding-left: var(--emu-common-spacing-large);
}

.page__container .aaaem-text b {
  font-family: var(--emu-semantic-font-families-mono);
}

.page__container .aaaem-text p {
  margin-bottom: 10px;
}

.page__heading {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
  color: var(--emu-semantic-colors-extra-primary-blue);
  margin-top: 10px;
  margin-bottom: 15px;
}

.page__heading p, .page__heading h1, .page__heading h2, .page__heading h3, .page__heading h4, .page__heading h5 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

.page__heading p .emphasis, .page__heading h1 .emphasis, .page__heading h2 .emphasis, .page__heading h3 .emphasis, .page__heading h4 .emphasis, .page__heading h5 .emphasis {
  color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
}

.page__heading p sup, .page__heading h1 sup, .page__heading h2 sup, .page__heading h3 sup, .page__heading h4 sup, .page__heading h5 sup {
  top: -10px;
}

.page__sub-heading.aaaem-text p {
  margin-bottom: var(--emu-semantic-spacing-none);
}

.page__text-small {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

.page__table {
  border-top: var(--emu-common-border-width-thin);
  border-right: var(--emu-common-border-width-thin);
  border-top-style: solid;
  border-right-style: solid;
  border-color: var(--emu-common-colors-grey-500);
  overflow-x: auto;
}

.page__table table {
  width: 100%;
}

.page__table tr {
  color: var(--emu-common-colors-black);
}

.page__table th, .page__table td {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.page__table th {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
  padding-top: 5px;
  padding-bottom: 5px;
}

.page__table th .emphasis {
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-family: Elysio light;
  line-height: 20px;
}

.page__table th:nth-child(2) {
  background-color: var(--emu-semantic-colors-extra-secondary-red-200);
}

.page__table td {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-200);
  border-bottom: 1px solid var(--emu-common-colors-grey-500);
  border-left: 1px solid var(--emu-common-colors-grey-500);
  padding-top: 15px;
  padding-bottom: 15px;
}

.page__table td:nth-child(1) {
  text-align: left;
  padding-left: 30px;
}

.page__table td:nth-child(2) {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-300);
}

.page__table-heading {
  margin-top: 10px;
  margin-bottom: 10px;
}

.page__table-footer-text {
  padding-top: 10px;
}

.card-container--three {
  grid-gap: 10px;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.card-container--three .card-container--image .aaaem-card__title {
  display: none;
}

.card-container--three .card-container--image .text .text-size--large p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  font-family: var(--emu-semantic-font-families-heading);
  word-break: break-word;
}

.card-container--three .card-container--image .text .text-size--large sup {
  font-size: 75%;
  top: -7px;
}

.card-container--three .card-container--image .text p {
  margin-bottom: 10px;
}

.card-container--three .card-container--image .text p .emphasis {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-bottom: 10px;
  line-height: 15px;
  display: block;
}

@media (min-width: 768px) {
  .card-container--three .card-container--image .text p .emphasis {
    padding-bottom: 40px;
  }
}

.card-container--three .card-container--image .aaaem-card__footer .aaaem-container {
  justify-content: space-between;
  display: flex;
}

.card-container--three .card-container--image .aaaem-image {
  max-width: 150px;
}

@media (min-width: 768px) {
  .card-container--three {
    flex-flow: wrap;
  }

  .card-container--three > div:nth-child(2n+1) {
    max-width: 244px;
    height: 205px;
  }

  .card-container--three > div:nth-child(2) {
    height: 205px;
    max-width: 487px;
    max-height: 204px;
  }

  .card-container--three > div:nth-child(2) .card-container--image {
    width: 100%;
    margin-bottom: var(--emu-common-spacing-none);
  }

  .card-container--three .card-container--image .text p {
    line-height: 15px;
  }

  .card-container--three .card-container--image .aaaem-image {
    position: relative;
    top: 15px;
  }

  .card-container--three .card-container--image .aaaem-card__footer .aaaem-container {
    align-items: flex-end;
  }

  .card-container--three .text .text-size--large p, .card-container--three .text .aaaem-text p {
    margin-bottom: var(--emu-common-spacing-none);
  }

  .card-container--three .card {
    position: relative;
  }

  .card-container--three .card > div, .card-container--three .card .aaaem-card, .card-container--three .card .aaaem-card__wrapper {
    height: 100%;
  }

  .card-container--three .card .aaaem-card__title-wrapper, .card-container--three .card .aaaem-card__content-wrapper {
    width: 100%;
  }

  .card-container--three .card footer {
    width: 93%;
    position: absolute;
    bottom: 15px;
  }
}

@media (min-width: 1024px) {
  .card-container--three > div:nth-child(2n+1) {
    max-width: 266px;
    flex: 1;
  }

  .card-container--three > div:nth-child(2) {
    flex: 2;
  }
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper nav.header__nav ul li.emu-navigation__item--active {
    background-color: var(--emu-semantic-colors-extra-primary-blue);
  }

  .general-header .header__content .header__nav-wrapper nav.header__nav ul li.emu-navigation__item--active > a, .general-header .header__content .header__nav-wrapper nav.header__nav ul li.emu-navigation__item--active > span, .general-header .header__content .header__nav-wrapper nav.header__nav ul li.emu-navigation__item--active > a > span, .general-header .header__content .header__nav-wrapper nav.header__nav ul li.emu-navigation__item--active > span > span {
    color: var(--emu-common-colors-white);
  }

  #home .aaaem-carousel {
    position: relative;
  }

  #home .aaaem-carousel__indicators {
    margin-top: var(--emu-component-containers-carousel-indicators-margin-top-wide);
    margin-bottom: var(--emu-component-containers-carousel-indicators-margin-bottom-wide);
    position: absolute;
    bottom: 0;
    left: 45%;
  }
}

@media (min-width: 1024px) {
  #home .aaaem-carousel img {
    height: 604px;
  }
}

#home .home-card-container {
  grid-gap: 10px;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

#home .home-card-container--slide-1, #home .home-card-container--slide-3 {
  opacity: 0;
  transition: opacity .4s linear;
}

#home .home-card-container--slide-1:not(.active), #home .home-card-container--slide-3:not(.active) {
  display: none;
}

#home .home-card-container--slide-1.active.active--show, #home .home-card-container--slide-3.active.active--show {
  opacity: 1;
}

#home .home-card-container--slide-3 .aaaem-text b {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .home-card-container {
    margin-top: var(--emu-common-spacing-small);
    flex-direction: row;
  }
}

#home .home-card-container > .card {
  position: relative;
}

@media (min-width: 768px) {
  #home .home-card-container > .card {
    max-width: 50%;
  }
}

#home .home-card-container > .card .aaaem-card__wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  #home .home-card-container > .card .aaaem-card__wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  #home .home-card-container > .card .aaaem-card__wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  #home .home-card-container > .card .aaaem-card__body {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #home .home-card-container > .card .aaaem-card__body {
    margin-bottom: 10px;
  }
}

#home .home-card-container > .card .aaaem-card__title-wrapper {
  display: none;
}

#home .home-card-container > .card .aaaem-card__content-wrapper {
  width: 100%;
}

#home .home-card-container > .card .aaaem-card__content-wrapper .aaaem-title {
  margin-bottom: 5px;
}

#home .home-card-container > .card .aaaem-card__content-wrapper .aaaem-text p {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #home .home-card-container > .card .aaaem-card__footer {
    position: absolute;
    bottom: 15px;
  }
}

#home .home-card-container > .card > div, #home .home-card-container > .card .aaaem-card, #home .home-card-container > .card .aaaem-card__wrapper {
  height: 100%;
}

#home .home-card-container > .card .home-card-before-after {
  display: none;
}

@media (min-width: 768px) {
  #home .home-card-container > .card .home-card-before-after {
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
  }

  #home .home-card-container > .card .home-card-before-after .aem-Grid {
    max-width: 88%;
  }
}

#home .footer-copyright-text ul li:nth-child(3) {
  display: block;
}

#home .footer-copyright-text ul li:nth-child(2) {
  display: none;
}

.cq-Editable-dom--container #home .home-card-container--slide-1, .cq-Editable-dom--container #home .home-card-container--slide-3 {
  opacity: 1;
  display: block;
}

#csd-page .csd-clinical__image {
  margin-top: 20px;
  margin-bottom: 20px;
}

#csd-page .csd-clinical__image img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 420px) {
  #csd-page .csd-clinical__image img {
    max-width: 60%;
  }
}

@media (min-width: 768px) {
  #csd-page .csd-clinical__image img {
    max-width: 443px;
  }
}

#csd-page .csd-clinical__image-two {
  background-color: var(--emu-common-colors-white);
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 20px 10px;
}

#csd-page .csd-clinical__image-two .aaaem-image {
  margin-left: 10px;
}

#csd-page .csd-clinical__image-two .aaaem-image img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #csd-page .csd-clinical__image-two .aaaem-image img {
    max-width: 866px;
  }
}

#csd-page .csd-clinical__image-two .aaaem-text {
  font-size: 12px;
  line-height: 17px;
}

#csd-page .csd-clinical__image-two .aaaem-text p {
  color: var(--emu-semantic-colors-extra-secondary-light-grey-900);
  margin-bottom: 10px;
}

#csd-page .emu-spacing-mb__none p {
  margin-bottom: var(--emu-semantic-sizing-none);
}

#mechanism-of-action .container--mechanism-of-action, #mechanism-of-action .container--image-all-text {
  flex-direction: column;
  display: flex;
}

#mechanism-of-action .text--mechanism-of-action {
  margin-top: 10px;
  margin-bottom: 15px;
}

#mechanism-of-action .text--nonhuman-nonanimal ul {
  padding-left: 20px;
}

#mechanism-of-action .text--nonhuman-nonanimal b {
  font-family: var(--emu-semantic-font-families-body);
}

#mechanism-of-action .text--pharmacokinetics {
  margin-top: 10px;
  margin-bottom: 10px;
}

#mechanism-of-action .text--pharmacokinetics p:first-child {
  margin-bottom: var(--emu-common-spacing-none);
}

#mechanism-of-action .text--pharmacokinetics p b {
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-500);
}

#mechanism-of-action .container--image-moa {
  background-color: var(--emu-common-colors-white);
  border-radius: 15px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  padding: 20px;
  display: flex;
}

#mechanism-of-action .container--image-moa .image .aaaem-image {
  max-width: 146px;
  margin-top: 18px;
  margin-right: 20px;
}

#mechanism-of-action .container--image-moa .text-container--moa {
  margin-top: 40px;
}

#mechanism-of-action .container--image-moa .text-container--moa .title .aaaem-title {
  text-align: center;
  margin-bottom: 10px;
}

#mechanism-of-action .container--image-moa .text-container--moa .title .aaaem-title h5 {
  line-height: 15px;
}

#mechanism-of-action .container--image-moa .text-container--moa .text {
  margin-bottom: 10px;
}

#mechanism-of-action .container--image-moa .text-container--moa .text .aaaem-text {
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-light-grey-600);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-light-grey-600);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  padding-top: 15px;
  padding-bottom: 5px;
}

@media (min-width: 420px) {
  #mechanism-of-action .container--image-moa {
    display: block;
    overflow: hidden;
  }

  #mechanism-of-action .container--image-moa .image, #mechanism-of-action .container--image-moa .container {
    float: left;
    width: 50%;
  }

  #mechanism-of-action .container--image-moa .image {
    width: 42%;
    margin-top: 18px;
    margin-right: 20px;
  }

  #mechanism-of-action .container--image-moa .image .aaaem-image {
    max-width: none;
    margin-right: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 570px) {
  #mechanism-of-action .container--image-moa .image {
    width: 30%;
  }
}

@media (min-width: 768px) {
  #mechanism-of-action .container--image-moa, #mechanism-of-action .container--image-moa .text-container--moa {
    margin-top: var(--emu-common-spacing-none);
  }

  #mechanism-of-action .container--image-moa .image {
    width: 44%;
  }

  #mechanism-of-action .container--image-all-text {
    flex-direction: row;
    justify-content: space-between;
  }

  #mechanism-of-action .container--image-all-text > div {
    width: 45%;
  }
}

#about-smf .about-smf__container {
  margin-bottom: 7px;
}

@media (min-width: 768px) {
  #about-smf .about-smf__container {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#about-smf .about-smf__container .aaaem-text p {
  margin-bottom: 10px;
}

#about-smf .about-smf__container .aaaem-text ul {
  text-indent: -8px;
  padding-left: 20px;
}

#about-smf .about-smf__sub-heading h5 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 20px;
}

#about-smf .about-smf-impact__container {
  background-color: var(--emu-common-colors-white);
  border-radius: 20px;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 20px 10px;
  display: flex;
}

#about-smf .about-smf-impact__container .image {
  max-width: 192px;
  margin-left: 10px;
}

@media (min-width: 768px) {
  #about-smf .about-smf-impact__container .image {
    margin-left: 13px;
  }
}

#about-smf .about-smf-rounded__text {
  color: var(--emu-common-colors-white);
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px 15px;
}

#about-smf .about-smf-rounded__text--mt__md {
  margin-top: 24px;
}

#about-smf .about-smf-rounded__text.aaaem-text p {
  margin-bottom: var(--emu-common-spacing-none);
}

#before-after .text--description {
  margin-bottom: 10px;
}

#before-after .text--description p .emphasis, #before-after .text--description ul > li .emphasis {
  font-weight: var(--emu-common-font-weight-bold);
}

#before-after .text--description ul {
  margin-bottom: 25px;
  padding-left: 20px;
}

#before-after .text--description a {
  color: var(--emu-semantic-colors-actions-on-primary-dark);
  text-decoration: underline;
}

#before-after .text--description-small {
  margin-bottom: 10px;
}

#before-after .text--description-small p {
  font-size: 11px;
  line-height: 15px;
}

#before-after #before-after-trigger-carousel {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

#before-after #before-after-trigger-carousel .image {
  min-width: 50px;
  max-width: 82px;
  cursor: pointer;
}

#before-after #before-after-trigger-carousel .active .image {
  opacity: .5;
}

#before-after .carousel--between-text {
  margin-top: 5px;
  margin-bottom: 15px;
}

#before-after .carousel--before-after {
  flex-direction: column-reverse;
  display: flex;
}

#before-after .carousel--before-after > div:nth-child(2) {
  display: none;
}

#before-after .carousel--before-after .tns-outer {
  position: relative;
}

#before-after .carousel--before-after .tns-outer .tns-ovh {
  width: 100%;
  height: auto !important;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner {
  margin: auto;
  overflow: hidden;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container {
  width: 100%;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .image {
  max-width: 224px;
  margin-left: auto;
  margin-right: auto;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .image .aaaem-image {
  border: 3px solid var(--emu-common-colors-white);
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .before-after-text {
  text-align: center;
  color: var(--emu-semantic-colors-surface-dark);
  font-size: 18px;
  line-height: 1;
}

#before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .before-after-text p {
  font-family: var(--emu-semantic-font-families-mono);
  margin-top: 3px;
  margin-bottom: 3px;
}

@media (min-width: 768px) {
  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .before-after-text p {
    max-width: 224px;
  }
}

@media (min-width: 540px) {
  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid {
    justify-content: space-between;
  }

  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container {
    width: 45%;
    float: none;
    margin-right: 23px;
  }
}

@media (min-width: 768px) {
  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid {
    justify-content: flex-start;
    padding-left: 30px;
  }

  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container {
    width: 224px;
    margin-right: 15px;
  }

  #before-after .carousel--before-after .tns-outer .tns-ovh .tns-inner .aaaem-carousel__content .carouselslide .aaaem-container .aem-Grid .container .image {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

#before-after .carousel--before-after--bottom-description {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-top: 20px;
  margin-bottom: 30px;
}

#before-after .carousel--before-after--bottom-description .emphasis {
  color: var(--emu-semantic-colors-surface-dark);
  font-family: var(--emu-semantic-font-families-heading);
}

#before-after .carousel--before-after--bottom-description b {
  font-family: var(--emu-semantic-font-families-body);
}

#before-after .carousel--before-after .aaaem-image img {
  height: 279px;
}

@media (min-width: 768px) {
  #before-after .carousel--before-after {
    flex-direction: column;
  }

  #before-after .carousel--before-after .tns-outer .tns-controls {
    position: relative;
    top: 60px;
  }

  #before-after .text-container--all {
    padding-right: 16px;
  }
}

@media (min-width: 1024px) {
  #before-after .carousel--before-after {
    flex-direction: column;
    margin-right: -15px;
  }

  #before-after .text-container--all {
    padding-right: 20px;
  }
}

#faq-page .accordion-container .aem-Grid {
  max-width: 696px;
}

#faq-page .accordion-container .button {
  text-align: end;
}

#faq-page .accordion-container .button .aaaem-button, #faq-page .accordion-container .button .aaaem-button:hover, #faq-page .accordion-container .button .aaaem-button:active {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
  letter-spacing: 1px;
  padding: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-heading);
  background: none;
  border: none;
  outline: none;
  margin-bottom: 7px;
  margin-left: 15px;
  font-weight: 700;
  text-decoration: underline;
}

#faq-page .accordion-container .button .aaaem-button:hover {
  text-decoration: none;
}

#faq-page .accordion-container .aaaem-accordion__icon {
  height: 12px;
  width: 12px;
}

#faq-page .accordion-container .aaaem-accordion__icon:before, #faq-page .accordion-container .aaaem-accordion__icon:after {
  height: 8px;
  top: 1px;
}

#faq-page .accordion-container .faq-page-small__text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-top: 15px;
  margin-left: -20px;
  line-height: 17px;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul {
  list-style: none;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li {
  padding-left: 12px;
  position: relative;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li:before {
  content: "— ";
  position: absolute;
  left: 0;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li > ul {
  margin-left: 26px;
  list-style: disc;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li > ul > li > ul {
  list-style: none;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li > ul > li > ul > li {
  padding-left: 12px;
  position: relative;
}

#faq-page .accordion-container .aaaem-text > ul > li > ul > li > ul > li > ul > li:before {
  content: "— ";
  position: absolute;
  left: 0;
}

#faq-page .accordion-container a {
  color: #337ab7;
  text-decoration: none;
}

#faq-page .accordion-container a u {
  text-decoration: none;
}

#faq-page .accordion-container a u:hover {
  text-underline-offset: 6px;
  text-decoration: underline;
}

#faq-page .accordion-container a .emphasis {
  color: var(--emu-semantic-colors-actions-primary-dark);
  border-bottom: 1px solid #0000;
  text-decoration: underline;
  display: inline-block;
}

#faq-page .accordion-container a:hover {
  color: #23527c;
}

#faq-page .accordion-container a:hover .emphasis {
  border-bottom-color: var(--emu-semantic-colors-actions-primary-dark);
}

#faq-page .footer-copyright-text ul li:nth-child(1) {
  display: block;
}

#faq-page .footer-copyright-text ul li:nth-child(2) {
  display: none;
}

#clinical-results .container--grad-image {
  background-color: var(--emu-common-colors-white);
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 20px 10px;
}

#clinical-results .container--grad-image .page__text-small {
  font-size: 13px;
  line-height: 18px;
}

#clinical-results .container--grad-image .text-heading--grad-improvement p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  text-align: center;
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-200);
  margin-bottom: 10px;
}

#clinical-results .container--grad-image .text-heading--grad-improvement p .emphasis {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

#clinical-results .container--grad-image .container-image--grade-images {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
}

#clinical-results .container--grad-image .container-image--grade-images .container {
  max-width: 88%;
}

#clinical-results .container--grad-image .container-image--grade-images .container .text .aaaem-text p {
  text-align: center;
  color: var(--emu-semantic-colors-extra-primary-blue);
}

@media (min-width: 420px) {
  #clinical-results .container--grad-image .container-image--grade-images {
    grid-gap: 20px;
    justify-content: space-between;
    gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #clinical-results .container--grad-image .container-image--grade-images .container {
    max-width: calc(50% - 10px);
  }
}

@media (min-width: 768px) {
  #clinical-results .container--grad-image .container-image--grade-images {
    grid-gap: min(20%, 90px);
    justify-content: center;
    gap: min(20%, 90px);
  }

  #clinical-results .container--grad-image .container-image--grade-images .container {
    max-width: 312px;
  }
}

#clinical-results .container--grad-image .text-clinical-trial--image-description p {
  font-family: var(--emu-semantic-font-families-heading);
}

#clinical-results .container--grad-image .text-clinical-trial--image-description p .emphasis {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

#clinical-results .container--grad-image .text-clinical-trial--image-description p i {
  font-style: normal;
  font-family: var(--emu-semantic-font-families-body);
}

#clinical-results .container--grad-image .text--number-of-treatments p {
  font-size: 15px;
}

#clinical-results .text--subject-details ul {
  padding-left: 25px;
}

#clinical-results .text--subject-details ul li {
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-200);
  line-height: 22px;
}

#clinical-results .text--subject-details ul li:not(:last-child) {
  margin-bottom: 6px;
}

#clinical-results .text--subject-details ul li b {
  font-family: var(--emu-semantic-font-families-body);
}

@media (min-width: 768px) {
  #clinical-results .text--subject-details ul li {
    margin-bottom: 3px;
  }
}

#clinical-results .container--bg-blue-sec .aaaem-container {
  min-height: 215px;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 25px 15px;
}

#clinical-results .container--bg-blue-sec .aaaem-container .image {
  margin-top: 10px;
  margin-right: 20px;
}

#clinical-results .container--bg-blue-sec .aaaem-container b {
  font-family: var(--emu-semantic-font-families-body);
}

#clinical-results .container--bg-blue-sec .aaaem-container.text-container--improvement-in-self-perception {
  margin-bottom: 20px;
}

#clinical-results .container--bg-blue-sec .aaaem-container.text-container--improvement-in-self-perception ul {
  text-align: center;
  padding-left: var(--emu-common-spacing-none);
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
}

#clinical-results .container--bg-blue-sec .aaaem-container.text-container--improvement-in-self-perception ul li {
  vertical-align: middle;
  display: inline-block;
}

#clinical-results .container--bg-blue-sec .aaaem-container.text-container--improvement-in-self-perception ul li:after {
  content: "";
  vertical-align: middle;
  width: 5px;
  height: 5px;
  background-color: var(--emu-common-colors-white);
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
  display: inline-block;
}

#clinical-results .container--bg-blue-sec .aaaem-container.text-container--improvement-in-self-perception ul li:last-child:after {
  display: none;
}

#clinical-results .container--bg-blue-sec .container-image--patient-satisfaction {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
}

#clinical-results .container--bg-blue-sec .container-image--patient-satisfaction .image .aaaem-image {
  width: 129px;
}

#clinical-results .container--bg-blue-sec .container-image--patient-satisfaction p {
  text-align: center;
}

@media (min-width: 420px) {
  #clinical-results .container--bg-blue-sec .container-image--patient-satisfaction {
    flex-wrap: nowrap;
  }

  #clinical-results .container--bg-blue-sec .container-image--patient-satisfaction p {
    text-align: left;
  }
}

@media (min-width: 768px) {
  #clinical-results .container--bg-blue-sec {
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  #clinical-results .container--bg-blue-sec .aaaem-text p {
    margin-bottom: var(--emu-common-spacing-none);
  }

  #clinical-results .container--bg-blue-sec .container-image--patient-satisfaction {
    margin-right: 20px;
  }

  #clinical-results .container--bg-blue-sec .container-image--patient-satisfaction > div:nth-child(2) {
    max-width: 65%;
  }
}

#clinical-results .text--safety-info a {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
}

#about-treatment .about-treatment-page__subtitle {
  margin-top: 30px;
  margin-bottom: 20px;
}

#about-treatment .about-treatment-page__disclaimer ul {
  margin-bottom: 10px;
}

#about-treatment .about-treatment-page__disclaimer p {
  margin-bottom: 20px;
}

#about-treatment .about-treatment-page__table-container {
  grid-gap: 20px;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

@media (min-width: 600px) {
  #about-treatment .about-treatment-page__table-container {
    grid-gap: 0;
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
  }

  #about-treatment .about-treatment-page__table-container > .container {
    width: 44%;
    margin-right: 6%;
  }
}

#about-treatment .about-treatment-page__image {
  max-width: 307px;
  margin-top: 25px;
  margin-bottom: 35px;
}

#about-treatment .page__table th {
  width: 18%;
  padding-top: 5px;
  padding-bottom: 5px;
}

#about-treatment .page__table th:nth-child(1) {
  width: 15%;
}

#about-treatment .page__table td {
  text-align: center;
  padding-left: 10px;
}

#about-treatment .page__table-footer-text {
  padding-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: 10px;
}

#about-treatment .page__container .aaaem-text ul {
  padding-left: 20px;
}

#about-treatment .page__container .aaaem-text a {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
}

#safety-page .page__container .aaaem-text p {
  margin-bottom: 10px;
  padding-top: 10px;
}

#safety-page .page__container .warnings p:last-child {
  margin-top: 30px;
}

#safety-page .page__table th {
  width: 17%;
}

#safety-page .page__table th:nth-child(1) {
  width: 33%;
}

#safety-page .page__table td {
  padding-left: 10px;
}

#safety-page .page__table td .emphasis {
  padding-left: 20px;
}

#safety-page .page__table-heading.aaaem-text {
  margin-bottom: var(--emu-common-spacing-none);
}

#safety-page .page__table-heading.aaaem-text p {
  margin-bottom: 5px;
}

#safety-page .page__table-container {
  width: 100%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #safety-page .page__table-container {
    width: 500px;
  }
}

#safety-page .page__table-footer-text {
  padding-top: var(--emu-common-spacing-none);
}

#about-kyb .aaaem-embed__embeddable {
  padding-bottom: calc(56.25% + 58px);
}

#about-kyb .text-container__for-adults {
  margin-top: 20px;
}

#about-kyb .image-container__bg-white {
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 20px 10px;
}

#about-kyb .image-container__bg-white .aem-Grid .image {
  width: 100%;
  margin-left: var(--emu-common-spacing-none);
  margin-bottom: 6px;
}

#about-kyb .image-container__bg-white .aem-Grid .image img {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

#about-kyb .image-container__bg-white .tailored-img img {
  max-width: min(100%, 399px);
}

#about-kyb .image-container__bg-white .one-in-three-img img {
  max-width: min(100%, 422px);
}

#about-kyb .image-container__bg-white .injection-box-img img {
  max-width: min(100%, 356px);
}

#welcomekit header, #welcomekit footer, #welcomekit .cmp-experiencefragment--isi {
  display: none;
}

#welcomekit .welcomekit-page-root header, #welcomekit .welcomekit-page-root footer, #welcomekit .welcomekit-page-root .cmp-experiencefragment--isi, #welcomekit [data-component="modal"][data-id="video"] .is-video-loaded video {
  display: block;
}

#welcomekit .modal-content {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-medium);
  max-width: calc(100% - var(--emu-common-spacing-medium));
  border-radius: var(--emu-common-border-radius-xs);
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 20%;
}

@media (min-width: 576px) {
  #welcomekit .modal-content {
    max-width: 500px;
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

#welcomekit .modal .close {
  width: auto;
  top: -7px;
  right: 5px;
}

#welcomekit .modal .close span {
  color: var(--emu-common-colors-black);
  opacity: .5;
  font-size: 24px;
  line-height: 1;
  display: block;
}

#welcomekit .banner--your-kyb-welcomekit {
  border-top: 3px solid var(--emu-semantic-colors-extra-secondary-red-200);
  border-bottom: 6px solid var(--emu-semantic-colors-extra-secondary-light-grey-1000);
  padding: 30px 15px 15px;
  position: relative;
}

#welcomekit .banner--your-kyb-welcomekit .image {
  display: none;
}

#welcomekit .banner--your-kyb-welcomekit .aaaem-text p {
  font-family: var(--emu-semantic-font-families-extra-thin);
  font-size: 17px;
  line-height: 25px;
}

#welcomekit .banner--your-kyb-welcomekit .aaaem-text p b {
  font-family: inherit;
  font-weight: var(--emu-common-font-weight-bold);
  display: inline-block;
}

@media (min-width: 768px) {
  #welcomekit .banner--your-kyb-welcomekit .aaaem-text p {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#welcomekit .banner--your-kyb-welcomekit .banner--text-large h1 {
  font-family: var(--emu-semantic-font-families-extra-light);
  margin-bottom: 8px;
  font-size: 46px;
  line-height: 55px;
}

#welcomekit .banner--your-kyb-welcomekit .banner--text-large sup {
  font-size: 31px;
  top: -12px;
}

#welcomekit .banner--your-kyb-welcomekit .banner--text-medium {
  font-family: var(--emu-semantic-font-families-extra-thin);
  margin-bottom: 24px;
  padding-left: 60px;
  padding-right: 60px;
  display: block;
}

#welcomekit .banner--your-kyb-welcomekit .banner--text-medium p {
  font-size: 25px;
  line-height: 37px;
}

#welcomekit .banner--your-kyb-welcomekit .banner--text-medium p sup {
  font-size: 17px;
  top: -7px;
  left: -1px;
}

@media (min-width: 768px) {
  #welcomekit .banner--your-kyb-welcomekit {
    padding-top: 20px;
    padding-left: 10px;
    overflow: hidden;
  }

  #welcomekit .banner--your-kyb-welcomekit .image {
    max-width: 536px;
    width: 100%;
    display: block;
    position: absolute;
    top: -185px;
    right: -275px;
  }

  #welcomekit .banner--your-kyb-welcomekit .banner--text-medium {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #welcomekit .banner--your-kyb-welcomekit .banner--all-text-container {
    width: 72%;
  }
}

@media (min-width: 992px) {
  #welcomekit .banner--your-kyb-welcomekit {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
  }

  #welcomekit .banner--your-kyb-welcomekit .banner--all-text-container {
    width: 66%;
  }

  #welcomekit .banner--your-kyb-welcomekit .banner--text-medium {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  #welcomekit .banner--your-kyb-welcomekit .banner--text-normal {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  #welcomekit .banner--your-kyb-welcomekit .image {
    top: auto;
    bottom: -40px;
    right: -95px;
  }
}

#welcomekit .welcome-kit__contact-container {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  #welcomekit .welcome-kit__contact-container {
    margin-top: 50px;
  }
}

#welcomekit .welcome-kit__contact-container .aaaem-text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 32px;
}

#welcomekit .welcome-kit__contact-container .aaaem-text p {
  font-family: var(--emu-semantic-font-families-extra-light);
}

@media (min-width: 768px) {
  #welcomekit .welcome-kit__contact-container .aaaem-text {
    font-size: 24px;
    line-height: 38px;
  }
}

@media (min-width: 992px) {
  #welcomekit .welcome-kit__contact-container .aaaem-text {
    width: 80%;
  }
}

#welcomekit .welcome-kit-section__container {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 992px) {
  #welcomekit .welcome-kit-section__container {
    max-width: var(--emu-semantic-sizing-large-tablet);
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  #welcomekit .welcome-kit-section__container .welcomekit-card-container:not(.welcomekit-card-container--no-border) {
    border-bottom: 2px solid #a7a9ac;
    margin-bottom: 40px;
    padding-bottom: 50px;
  }
}

#welcomekit .welcome-kit-section__heading {
  padding-top: 30px;
  padding-bottom: 15px;
}

#welcomekit .welcome-kit-section__heading p {
  font-size: 32px;
  line-height: 38px;
  font-family: var(--emu-semantic-font-families-extra-light);
  padding-left: 10px;
  padding-right: 10px;
}

#welcomekit .welcome-kit-section__heading p sup {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  top: -10px;
}

@media (min-width: 768px) {
  #welcomekit .welcome-kit-section__heading p {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#welcomekit .welcome-kit-section__desc {
  font-size: 17px;
  line-height: 26px;
}

#welcomekit .welcome-kit-section__desc p {
  font-family: var(--emu-semantic-font-families-extra-thin);
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-600);
  text-shadow: 0px 0px 0px var(--emu-semantic-colors-extra-secondary-dark-grey-600);
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #welcomekit .welcome-kit-section__desc p {
    letter-spacing: -.25px;
    margin-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 25px;
  }
}

@media (min-width: 992px) {
  #welcomekit .welcome-kit-section__desc p {
    letter-spacing: normal;
    padding-left: 105px;
    padding-right: 105px;
  }
}

#welcomekit .card .aaaem-card__title-wrapper {
  display: none;
}

#welcomekit .card .aaaem-card__content-wrapper {
  width: auto;
}

#welcomekit .card .aaaem-image img {
  -o-object-fit: contain;
  object-fit: contain;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-container .bg--primary-blue, #welcomekit .card .aaaem-card__footer .bg--primary-blue {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
}

#welcomekit .card .aaaem-card__wrapper .aaaem-container .bg--secondary-red, #welcomekit .card .aaaem-card__footer .bg--secondary-red {
  background-color: var(--emu-semantic-colors-extra-secondary-red-200);
}

#welcomekit .card .aaaem-card__footer .bg--secondary-red, #welcomekit .card .aaaem-card__footer .bg--primary-blue {
  width: 100%;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 30px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #welcomekit .card .aaaem-card__footer .bg--secondary-red, #welcomekit .card .aaaem-card__footer .bg--primary-blue {
    min-height: 115px;
    align-items: flex-end;
  }
}

#welcomekit .card .aaaem-card__footer .button {
  width: 100%;
}

#welcomekit .card .aaaem-card__footer .welcomekit-button {
  color: var(--emu-common-colors-white);
  border: var(--emu-common-border-width-thin) solid #fff6;
  line-height: var(--emu-semantic-line-heights-narrow-large);
  width: 100%;
  max-width: 280px;
  font-family: var(--emu-semantic-font-families-extra-light);
  letter-spacing: 3px;
  background-color: var(--emu-semantic-colors-none);
  max-height: 55px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--emu-common-spacing-none);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  display: flex;
}

#welcomekit .card .aaaem-card__footer .welcomekit-button .cmp-button__text {
  border-bottom: none;
}

#welcomekit .card .aaaem-card__footer .welcomekit-button--download > span {
  max-width: 110px;
  display: inline-block;
}

#welcomekit .card .aaaem-card__wrapper {
  padding: var(--emu-common-spacing-none);
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #welcomekit .card .aaaem-card__wrapper .aaaem-card__body > .cmp-container .container {
    min-height: 195px;
  }
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .card--top-image {
  height: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .card--top-image img {
  max-width: 350px;
  max-height: 245px;
  padding: 5px 20px;
}

@media (min-width: 768px) {
  #welcomekit .card .aaaem-card__wrapper .aaaem-card__body .card--top-image img {
    padding: var(--emu-common-spacing-none);
    max-height: 235px;
  }
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text {
  margin-left: 24px;
}

@media (min-width: 768px) {
  #welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text {
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  #welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text {
    margin-left: 21px;
  }
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text h3 {
  font-family: var(--emu-semantic-font-families-extra-light);
  font-size: var(--emu-common-font-sizes-narrow-xl);
  letter-spacing: 1px;
  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 28px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text sup {
  font-size: 68%;
  left: -1px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text li, #welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text p {
  font-size: 17px;
  line-height: 25px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text ul {
  padding-left: var(--emu-common-spacing-none);
  list-style-type: none;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text ul li {
  font-family: var(--emu-semantic-font-families-extra-thin);
  padding-left: 14px;
  position: relative;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text ul li:before {
  content: "";
  background-color: var(--emu-common-colors-white);
  width: 5px;
  height: 5px;
  border: var(--emu-semantic-border-width-thin) solid var(--emu-common-colors-white);
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 3px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .aaaem-text ul li ul li:before {
  height: 1px;
  width: 7px;
  background-color: var(--emu-common-colors-white);
  border: none;
  border-radius: 0;
  display: block;
  top: 12px;
  left: 5px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .container--logo-all-text {
  padding-top: 25px;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .container--logo-all-text > .aem-Grid {
  width: 95%;
  margin: auto;
  display: flex;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .container--logo-all-text > .aem-Grid .container--all-text .aem-Grid {
  flex-direction: column;
  display: flex;
}

#welcomekit .card .aaaem-card__wrapper .aaaem-card__body .container--logo-all-text > .aem-Grid > div:nth-child(2) {
  width: 90%;
  margin-left: var(--emu-common-spacing-none);
}

#welcomekit .card .card--icon {
  width: 53px;
}

@media (min-width: 992px) {
  #welcomekit .card .card--icon {
    width: 50px;
  }
}

@media (min-width: 768px) {
  #welcomekit .card .left-card--margin-right {
    margin-right: 6px;
  }

  #welcomekit .card .aaaem-card__wrapper .aaaem-card__body .container--logo-all-text {
    height: 195px;
  }
}

#error-page .error-page-container .button {
  text-align: center;
}

#best-practice-guide header, #best-practice-guide footer, #best-practice-guide .cmp-experiencefragment--isi {
  display: none;
}

#best-practice-guide .best-practice-guide-inner header, #best-practice-guide .best-practice-guide-inner footer, #best-practice-guide .best-practice-guide-inner .cmp-experiencefragment--isi {
  display: block;
}

#best-practice-guide .best-prac-banner-container {
  background-color: #9faab0;
  flex-direction: column-reverse;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-banner-container {
    flex-direction: row;
  }
}

#best-practice-guide .best-prac-banner-container > .container {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

#best-practice-guide .best-prac-banner-container .aaaem-text {
  margin-bottom: 10px;
  font-size: 48px;
  line-height: 58px;
}

#best-practice-guide .best-prac-banner-container .aaaem-text h1 {
  font-family: var(--emu-semantic-font-families-body);
}

#best-practice-guide .best-prac-banner-image {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-banner-image {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#best-practice-guide .best-prac-banner-image .image {
  background-color: var(--emu-common-colors-white);
  transform: rotate3d(0, 0, 1, 2deg);
  box-shadow: 6px 7px 9px 3px #0000004a;
}

#best-practice-guide .best-prac-banner-image .image .aaaem-image {
  max-height: 300px;
}

#best-practice-guide .best-prac-banner-image .image .aaaem-image img {
  height: auto;
  width: 100%;
  max-height: 300px;
}

#best-practice-guide .best-prac-actnow-content {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 40px 55px;
}

#best-practice-guide .best-prac-actnow-text {
  margin-bottom: var(--emu-common-spacing-medium);
  color: #636466;
  font-size: 19px;
  line-height: 29px;
}

#best-practice-guide .best-prac-actnow-text p {
  font-family: var(--emu-semantic-font-families-extra-light);
}

#best-practice-guide .best-prac-actnow-title {
  margin-bottom: var(--emu-common-spacing-small);
  color: #65666b;
  font-size: 42px;
  line-height: 51px;
}

#best-practice-guide .best-prac-actnow-title h3 {
  font-family: var(--emu-semantic-font-families-body);
}

#best-practice-guide .best-prac-actnow-title h3 .emphasis {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: 60px;
  line-height: 72px;
}

#best-practice-guide .best-prac-actnow-inner-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-actnow-inner-container {
    flex-direction: row;
  }
}

#best-practice-guide .best-prac-actnow-inner-container .image, #best-practice-guide .best-prac-actnow-inner-container .container {
  text-align: center;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-actnow-inner-container .image, #best-practice-guide .best-prac-actnow-inner-container .container {
    margin-bottom: var(--emu-semantic-spacing-none);
    text-align: left;
  }
}

#best-practice-guide .best-prac-actnow-inner-container .image {
  padding-left: var(--emu-common-spacing-none);
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-actnow-inner-container .image {
    margin-bottom: 20px;
  }

  #best-practice-guide .best-prac-actnow-inner-container .container {
    margin-bottom: 10px;
  }
}

#best-practice-guide .best-prac-actnow-inner-container .aaaem-image {
  background-color: var(--emu-common-colors-white);
  transform: rotate3d(0, 0, 1, 358deg);
  box-shadow: 6px 7px 9px 3px #0000004a;
}

#best-practice-guide .best-prac-act-title {
  margin-bottom: var(--emu-common-spacing-small);
  color: #636466;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 71px;
  line-height: 85px;
}

#best-practice-guide .best-prac-act-title h2 {
  font-family: var(--emu-semantic-font-families-extra-light);
}

#best-practice-guide .best-prac-act-title h2 .emphasis {
  font-family: var(--emu-semantic-font-families-heading);
}

#best-practice-guide .best-prac-brand-title {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: 34px;
  font-family: var(--emu-semantic-font-families-body);
  color: var(--emu-semantic-colors-extra-secondary-light-grey-100);
  text-align: center;
  background-color: var(--emu-semantic-colors-extra-primary-blue);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-bottom: 10px;
  padding: 11px;
}

#best-practice-guide .best-prac-brand-inner-container, #best-practice-guide .best-prac-brand-inner-container-wrapper {
  flex-direction: column;
  display: flex;
}

#best-practice-guide .best-prac-brand-inner-container-wrapper {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-brand-inner-container-wrapper {
    flex-direction: row;
  }
}

#best-practice-guide .best-prac-brand-inner-container-wrapper > .container {
  flex: 1;
}

#best-practice-guide .best-prac-brand-left-container, #best-practice-guide .best-prac-brand-right-container {
  height: 100%;
  background: #e7e7e7;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px;
}

#best-practice-guide .best-prac-brand-left-container img, #best-practice-guide .best-prac-brand-right-container img {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-brand-left-container, #best-practice-guide .best-prac-brand-right-container {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    margin-left: 5px;
    margin-right: 5px;
  }
}

#best-practice-guide .best-prac-brand-left-container .image, #best-practice-guide .best-prac-brand-right-container .image {
  flex: 1;
}

@media (min-width: 768px) {
  #best-practice-guide .best-prac-brand-left-container {
    border-bottom-left-radius: 40px;
  }

  #best-practice-guide .best-prac-brand-right-container {
    border-bottom-right-radius: 40px;
  }
}

#best-practice-guide .best-prac-left-image, #best-practice-guide .best-prac-right-image {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

#best-practice-guide .best-prac-left-image {
  max-width: 283px;
}

#best-practice-guide .best-prac-left-image img {
  width: 100%;
}

#best-practice-guide .best-prac-right-image {
  max-width: 211px;
}

#best-practice-guide .best-prac-right-image img {
  width: 100%;
}

#best-practice-guide .interest-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

#best-practice-guide .interest-container > .text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

#best-practice-guide .interest-container > .text .aaaem-text {
  font-size: 20px;
  line-height: 32px;
}

#best-practice-guide .interest-container > .text .aaaem-text p {
  font-family: var(--emu-semantic-font-families-extra-light);
}

@media (min-width: 768px) {
  #best-practice-guide .interest-container > .text .aaaem-text {
    font-size: 24px;
    line-height: 38px;
  }
}

#best-practice-guide .aaaem-text .emphasis {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--emu-semantic-colors-extra-secondary-red-100);
  text-decoration-color: var(--emu-semantic-colors-extra-secondary-red-100);
}

#best-practice-guide .best-prac-buttons-container {
  border-bottom: 3px solid #b6c0c1;
  margin-bottom: 24px;
  padding: 48px 15px;
}

#best-practice-guide .button--bg-red-blue, #best-practice-guide .button--text-red-blue, #best-practice-guide .button--text-red-black {
  line-height: var(--emu-semantic-line-heights-narrow-large);
  width: 100%;
  max-width: 280px;
  font-family: var(--emu-semantic-font-families-extra-light);
  letter-spacing: 3px;
  max-height: 55px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--emu-common-spacing-none);
  text-align: center;
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  border: var(--emu-common-border-width-thin) solid #fff6;
  border-radius: var(--emu-common-border-radius-xs);
  justify-content: center;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

#best-practice-guide .button--bg-red-blue > span, #best-practice-guide .button--text-red-blue > span, #best-practice-guide .button--text-red-black > span {
  max-width: 110px;
  display: inline-block;
}

#best-practice-guide .button--bg-red-blue:hover, #best-practice-guide .button--text-red-blue:hover, #best-practice-guide .button--text-red-black:hover {
  color: var(--emu-common-colors-white);
  background-color: #0069d9;
  border-color: #0062cc;
}

#best-practice-guide .button--text-red-blue {
  border: var(--emu-common-border-width-none);
  border-radius: var(--emu-common-border-radius-none);
}

#best-practice-guide .button--text-red-blue:hover {
  color: #0056b3;
  background-color: var(--emu-semantic-colors-extra-secondary-red-200);
  text-decoration: underline;
}

#best-practice-guide .button--text-red-black {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-red-200);
  border-radius: var(--emu-common-border-radius-none);
  background-color: var(--emu-semantic-colors-none);
  color: var(--emu-semantic-colors-extra-secondary-red-200);
}

#best-practice-guide .button--text-red-black > span {
  max-width: 100%;
}

#best-practice-guide .button--text-red-black:hover {
  background-color: var(--emu-semantic-colors-none);
  color: #212529;
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-red-200);
}

#best-practice-guide .aaaem-button__primary-filled:hover .cmp-button__text {
  border: none;
}

#setup-account .footer-copyright-text ul li:nth-child(1) {
  display: block;
}

#setup-account .footer-copyright-text ul li:nth-child(2) {
  display: none;
}

.accordion.panelcontainer {
  border-width: var(--emu-common-border-width-thin);
  border-top-style: solid;
  border-color: var(--emu-semantic-colors-extra-secondary-light-grey-700);
}

.accordion.panelcontainer .aaaem-accordion__button {
  flex-direction: row-reverse;
  justify-content: flex-start;
  display: flex;
}

.accordion.panelcontainer .aaaem-accordion__button:hover {
  opacity: .8;
}

.accordion.panelcontainer .aaaem-accordion__icon {
  border-width: var(--emu-common-border-width-thin);
  border-style: solid;
  border-color: var(--emu-semantic-colors-extra-secondary-light-grey-800);
  align-self: center;
  margin-bottom: 5px;
}

.accordion.panelcontainer .aaaem-accordion__title {
  padding-bottom: 5px;
  padding-left: 25px;
  line-height: 19px;
}

.accordion.panelcontainer .aaaem-accordion__header.-ba-is-active {
  padding-bottom: 3px;
}

.accordion.panelcontainer .aaaem-accordion__panel .aaaem-text ul, .accordion.panelcontainer .aaaem-accordion__panel .aaaem-text li, .accordion.panelcontainer .aaaem-accordion__panel .aaaem-text p, .accordion.panelcontainer .aaaem-accordion__panel .aaaem-text a {
  color: var(--emu-semantic-colors-text-light);
}

.accordion.panelcontainer .aaaem-accordion__panel .aaaem-text ul, .accordion.panelcontainer .aaaem-accordion__panel .aaaem-text p {
  padding-left: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
}

.accordion.panelcontainer .aaaem-accordion__panel.-ba-is-active {
  border-width: var(--emu-common-border-width-thin);
  border-bottom-style: solid;
  border-color: var(--emu-semantic-colors-extra-secondary-light-grey-700);
}

.aaaem-button__primary-filled:hover .cmp-button__text {
  border-bottom: var(--emu-semantic-border-width-thin) solid var(--emu-common-colors-white);
}

.card .aaaem-card {
  overflow: hidden;
}

.card .aaaem-card__body {
  margin-bottom: 10px;
}

.card .aaaem-card__body .aaaem-text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-bottom: 5px;
}

.card .aaaem-card__body .aaaem-text b {
  font-family: var(--emu-semantic-font-families-mono);
  line-height: 20px;
  display: inline-block;
}

@media (min-width: 768px) {
  .card .aaaem-card__body .aaaem-text b {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.card .aaaem-card__body .aaaem-text b sup {
  font-family: var(--emu-semantic-font-families-body);
}

.card .aaaem-card__body .aaaem-text sup {
  font-size: 8px;
}

.card .aaaem-card__footer .aaaem-button {
  margin-top: 10px;
}

.card .aaaem-card__title-wrapper, .card .aaaem-card__content-wrapper {
  width: 93%;
  margin: auto;
}

.aaaem-carousel__action {
  top: 30%;
}

.aaaem-carousel__action-prev {
  left: 0;
  transform: translateX(-100%);
}

.aaaem-carousel__action-next {
  right: 0;
  transform: translateX(100%);
}

.emu-consent-banner__settings-cta {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  padding-top: 10px;
  padding-bottom: 10px;
}

.emu-consent-banner__settings-cta:active, .emu-consent-banner__settings-cta:hover {
  color: var(--emu-common-colors-white);
}

#best-practice-guide .cmp-experiencefragment--footer {
  margin-top: 1%;
}

#best-practice-guide .cmp-experiencefragment--footer footer {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  padding: 20px 10px;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul {
  padding-left: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-medium);
  flex-direction: column;
  display: flex;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li {
  margin-top: 10px;
  margin-bottom: 10px;
  list-style: none;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li a {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  font-family: var(--emu-semantic-font-families-extra-light);
  text-decoration: none;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li a:hover {
  text-decoration: underline;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container {
  flex-direction: column;
  display: flex;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .footer__image, #best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .footer__bottom {
  padding-left: 15px;
  padding-right: 15px;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .image {
  width: 100%;
  max-width: 250px;
}

#best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .footer__bottom {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

@media (min-width: 768px) {
  #best-practice-guide .cmp-experiencefragment--footer footer {
    max-width: 100%;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 13px;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul > li:first-child:before {
    content: "";
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    position: relative;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li:before {
    content: "|";
    position: absolute;
    left: -12px;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container {
    flex-direction: row;
    align-items: center;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .image {
    max-width: 206px;
  }
}

@media (min-width: 1024px) {
  #best-practice-guide .cmp-experiencefragment--footer footer {
    padding: 25px 40px;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer-link-list ul li:before {
    left: -22px;
  }

  #best-practice-guide .cmp-experiencefragment--footer footer .footer__details-container .image {
    max-width: 236px;
  }
}

.cmp-experiencefragment--footer {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
}

.cmp-experiencefragment--footer .hcp-kyb-custom-container {
  max-width: var(--emu-semantic-sizing-breakpoints-large);
  margin-left: auto;
  margin-right: auto;
}

.cmp-experiencefragment--footer footer {
  max-width: 96%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
}

.cmp-experiencefragment--footer footer sup {
  margin-right: 3px;
  font-size: 25px;
  line-height: 20px;
  display: inline-block;
  top: 6px;
}

.cmp-experiencefragment--footer footer .emphasis sup {
  margin-right: var(--emu-common-spacing-none);
  font-size: 15px;
  line-height: 1;
  display: inline;
  top: 0;
}

.cmp-experiencefragment--footer footer .footer-copyright-text ul {
  padding: var(--emu-common-sizing-none);
  list-style: none;
}

.cmp-experiencefragment--footer footer .footer-copyright-text ul li {
  display: none;
}

.cmp-experiencefragment--footer footer .footer-copyright-text ul li:nth-child(2), .cq-Editable-dom--container .cmp-experiencefragment--footer footer .footer-copyright-text ul li {
  display: block;
}

@media (min-width: 1024px) {
  .cmp-experiencefragment--footer footer {
    max-width: 100%;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1054px) {
  .cmp-experiencefragment--footer footer {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.cmp-experiencefragment--footer footer .footer__top-links-list ul {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer footer .footer__top-links-list ul {
    flex-wrap: nowrap;
    justify-content: space-between;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .cmp-experiencefragment--footer footer .footer__top-links-list ul {
    display: block;
  }
}

.cmp-experiencefragment--footer footer .footer__top-links-list li {
  padding-bottom: 20px;
  list-style: none;
}

.cmp-experiencefragment--footer footer .footer__top-links-list li a {
  text-decoration: none;
}

.cmp-experiencefragment--footer footer .footer__top-links-list li a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer footer .footer__top-links-list li {
    flex: auto;
    justify-content: space-around;
    display: flex;
  }

  .cmp-experiencefragment--footer footer .footer__top-links-list li:after {
    content: "|";
    color: var(--emu-common-colors-white);
    display: inline-block;
  }

  .cmp-experiencefragment--footer footer .footer__top-links-list li:last-child:after {
    display: none;
  }
}

@media (min-width: 1024px) {
  .cmp-experiencefragment--footer footer .footer__top-links-list li {
    display: inline-flex;
  }

  .cmp-experiencefragment--footer footer .footer__top-links-list li:after {
    margin-left: 23px;
    margin-right: 23px;
  }
}

.cmp-experiencefragment--footer footer .footer__bottom-section {
  margin-bottom: 20px;
}

.cmp-experiencefragment--footer footer .footer__bottom-section .image {
  max-width: 200px;
}

.cmp-experiencefragment--footer footer .footer__bottom-section .aaaem-text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .cmp-experiencefragment--footer footer .footer__bottom-section .aaaem-text {
    margin-top: 20px;
    margin-left: 30px;
  }
}

#welcomekit .cmp-experiencefragment--footer footer {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-500);
  max-width: none;
  padding: 20px 10px;
}

@media (min-width: 992px) {
  #welcomekit .cmp-experiencefragment--footer footer {
    padding: 25px 40px;
  }
}

#welcomekit .cmp-experiencefragment--footer footer .image {
  max-width: 33.33%;
  margin-bottom: var(--emu-common-spacing-small);
}

#welcomekit .cmp-experiencefragment--footer footer .image img {
  width: 100%;
}

@media (min-width: 768px) {
  #welcomekit .cmp-experiencefragment--footer footer .image {
    max-width: 25%;
  }
}

#welcomekit .cmp-experiencefragment--footer footer .footer-link-list ul {
  padding-left: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-medium);
  flex-flow: column wrap;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  #welcomekit .cmp-experiencefragment--footer footer .footer-link-list ul {
    padding: var(--emu-common-spacing-none);
    flex-direction: row;
  }
}

#welcomekit .cmp-experiencefragment--footer footer .footer-link-list li {
  margin-top: 10px;
  margin-bottom: 10px;
  list-style: none;
}

#welcomekit .cmp-experiencefragment--footer footer .footer-link-list li a {
  font-family: var(--emu-semantic-font-families-extra-light);
  line-height: 24px;
  text-decoration: none;
}

#welcomekit .cmp-experiencefragment--footer footer .footer-link-list li a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  #welcomekit .cmp-experiencefragment--footer footer .footer-link-list li {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    display: inline-flex;
  }

  #welcomekit .cmp-experiencefragment--footer footer .footer-link-list li:after {
    content: "|";
    color: var(--emu-common-colors-white);
    margin-top: var(--emu-semantic-spacing-none);
    margin-bottom: var(--emu-semantic-spacing-none);
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
  }

  #welcomekit .cmp-experiencefragment--footer footer .footer-link-list li:last-child:after {
    display: none;
  }
}

@media (min-width: 992px) {
  #welcomekit .cmp-experiencefragment--footer footer .footer-link-list li:after {
    margin-left: 35px;
    margin-right: 14px;
  }
}

#welcomekit .cmp-experiencefragment--footer footer .footer__bottom {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

#welcomekit .cmp-experiencefragment--footer footer .footer__bottom sup {
  margin-right: var(--emu-common-spacing-none);
  font-size: 14px;
  top: 0;
}

#best-practice-guide .bpg-header__logo {
  max-width: 297px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.general-header .header {
  max-width: var(--emu-semantic-sizing-breakpoints-large);
  margin-left: auto;
  margin-right: auto;
}

.general-header .header .cmp-container-header {
  padding-bottom: 40px;
  position: relative;
}

.general-header .header__logo .aaaem-image {
  max-width: 289px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 12px;
}

@media (min-width: 768px) {
  .general-header .header__logo {
    z-index: 2;
    position: relative;
  }
}

.general-header .header__content {
  width: 100%;
  background-color: var(--emu-semantic-colors-none);
  position: absolute;
  top: 0;
  right: 0;
}

.general-header .header__content .button {
  justify-content: flex-end;
  display: flex;
}

.general-header .header__content .button .aaaem-button {
  background-color: var(--emu-semantic-colors-none);
}

.general-header .header__content .button .aaaem-button img {
  max-width: 21px;
}

.general-header .header__content .button .header__menu-trigger {
  padding: var(--emu-common-spacing-none);
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 9px;
  right: 25px;
}

.general-header .header__content .text .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  color: var(--emu-semantic-colors-extra-secondary-light-grey-400);
}

.general-header .header__content .text .header__disclaimer-text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 107px;
}

@media (min-width: 540px) {
  .general-header .header .cmp-container-header {
    padding-bottom: var(--emu-common-spacing-none);
  }

  .general-header .header .cmp-container-header .header__content .text .header__disclaimer-text {
    text-align: right;
    padding-right: 25px;
    top: 60px;
  }
}

@media (min-width: 768px) {
  .general-header .header .cmp-container-header .header__content {
    position: static;
  }

  .general-header .header .cmp-container-header .header__content .text .header__disclaimer-text {
    padding-right: 15px;
    top: 10px;
  }

  .general-header .header__content .button {
    display: none;
  }

  .general-header .header__logo .aaaem-image {
    max-width: 260px;
  }
}

@media (min-width: 1024px) {
  .general-header .header__logo .aaaem-image {
    max-width: 289px;
  }

  .general-header .header .cmp-container-header .header__content .text .header__disclaimer-text {
    padding-right: 0;
  }
}

.general-header .header__content .header__nav-wrapper {
  border-right: var(--emu-common-border-width-thin) solid var(--emu-common-colors-grey-600);
  height: 100vh;
  max-width: 260px;
  z-index: var(--emu-common-other-z-index-modal);
  background-color: var(--emu-common-colors-white);
  padding-left: 28px;
  padding-right: 28px;
  transition: all .4s linear;
  position: fixed;
  top: 0;
  overflow-y: auto;
  transform: translateX(-100%);
}

.general-header .header__content .header__nav-wrapper .button {
  margin: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-modal);
  z-index: 700;
  position: absolute;
  top: 18px;
  right: 15px;
}

.general-header .header__content .header__nav-wrapper .button .aaaem-button {
  padding: var(--emu-common-spacing-none);
}

.general-header .header__content .header__nav-wrapper .button .aaaem-button img {
  max-width: 17px;
}

.general-header .header__content .header__nav-wrapper .text {
  z-index: var(--emu-common-other-z-index-modal);
  justify-content: center;
  align-items: center;
  display: flex;
}

.general-header .header__content .header__nav-wrapper .emu-navigation__content-wrapper {
  background-color: var(--emu-semantic-colors-none);
}

.general-header .header__content .header__nav-wrapper .header__nav {
  height: 100vh;
  justify-content: center;
  padding-top: 220px;
  display: flex;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > i, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > span > i {
  display: none;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > b, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > span > b {
  font-weight: inherit;
  font-family: inherit;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a sup, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > span sup {
  line-height: 0;
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > i, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > span > i {
    display: inline;
  }

  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > b, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item > a > span > b {
    display: none;
  }
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__item:nth-child(4) a i {
  font-style: normal;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 {
  max-width: 203px;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent {
  padding-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  position: relative;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > ul {
  padding-left: 11px;
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > ul {
    padding-left: var(--emu-common-sizing-none);
  }
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a {
  margin-top: 15px;
  margin-bottom: 15px;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a span {
  display: inline-block;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a span:hover {
  text-decoration: underline;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent .emu-navigation__menu--level-1 {
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-light-grey-200);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-light-grey-200);
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent .emu-navigation__menu--level-1 {
    border-bottom: none;
  }
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent .emu-navigation__menu--level-1 a {
  padding-bottom: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  border-top: 8px solid var(--emu-semantic-colors-extra-secondary-light-grey-200);
  position: absolute;
  top: 22px;
  right: 5px;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile a {
  color: var(--emu-common-colors-white);
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile {
    background-color: var(--emu-common-colors-white);
  }

  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile > a, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile > a > span {
    color: var(--emu-semantic-colors-extra-primary-blue);
  }

  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile:hover, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile.emu-navigation__item--active {
    background-color: var(--emu-semantic-colors-extra-primary-blue);
  }

  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile:hover > a, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile:hover > a > span, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile.emu-navigation__item--active > a, .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent.js-open--mobile.emu-navigation__item--active > a > span {
    color: var(--emu-common-colors-white);
  }
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 li {
  padding: 15px 15px 15px 11px;
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 li:hover {
  background-color: var(--emu-semantic-colors-extra-primary-blue);
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 li:hover a {
  color: var(--emu-common-colors-white);
}

.general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 li a {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-family: var(--emu-semantic-font-families-mono);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-component-lists-navigation-banner-item-text-color-default-light);
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 li a:hover {
    color: var(--emu-component-lists-navigation-banner-item-text-color-hover-light);
    text-decoration: underline;
  }
}

.general-header .header__content .header__nav-wrapper .header__prenav {
  max-width: 203px;
  width: 100%;
}

.general-header .header__content .header__nav-wrapper .header__prenav ul {
  padding-left: var(--emu-common-spacing-none);
  width: 100%;
  margin-top: 20px;
}

.general-header .header__content .header__nav-wrapper .header__prenav ul li {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-light-grey-400);
  padding: 15px 10px;
  list-style: none;
}

.general-header .header__content .header__nav-wrapper .header__prenav ul li a {
  font-size: 13px;
  line-height: var(--emu-semantic-line-heights-narrow-large);
  color: var(--emu-semantic-colors-extra-secondary-light-grey-400);
  text-transform: uppercase;
  font-family: var(--emu-semantic-font-families-mono);
  text-decoration: none;
}

.general-header .header__content .header__nav-wrapper .header__prenav ul li a:active, .general-header .header__content .header__nav-wrapper .header__prenav ul li a:hover {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

.general-header .header__content .header__nav-wrapper .header__prenav ul li a:hover {
  text-decoration: underline;
}

.general-header .header__content .header__nav-wrapper .header__prenav ul li:nth-child(3) a {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

.general-header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 {
  border: none;
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 {
    background-color: var(--emu-semantic-colors-surface-dark);
  }
}

.general-header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 li {
  padding-right: var(--emu-common-spacing-none);
}

.general-header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 li a {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

@media (min-width: 768px) {
  .general-header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 li a:hover {
    color: var(--emu-common-colors-white);
    background-color: var(--emu-semantic-colors-surface-dark);
  }
}

.general-header .header__content .js-toggle-on {
  transform: translateX(0);
}

@media (min-width: 540px) {
  .general-header .header__content .header__nav-wrapper {
    max-width: 300px;
    width: 100%;
  }

  .general-header .header__content .header__nav-wrapper .header__nav {
    padding-top: 205px;
  }

  .general-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 {
    width: 100%;
    max-width: 240px;
  }

  .general-header .header__content .header__nav-wrapper .header__prenav {
    max-width: 240px;
  }
}

@media (min-width: 768px) {
  .general-header .header {
    position: relative;
  }

  .general-header .header .header__content .header__nav-wrapper {
    height: auto;
    max-width: 100%;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    border-right: none;
    transition: none;
    display: block;
    position: static;
    overflow: visible;
    transform: none;
  }

  .general-header .header .header__content .header__nav-wrapper .text {
    background-color: var(--emu-semantic-colors-none);
    display: block;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav {
    max-width: none;
    width: auto;
    position: absolute;
    top: 48px;
    right: 15px;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav ul {
    margin-top: var(--emu-common-spacing-none);
    display: flex;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav ul li {
    border-bottom: none;
    border-right: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-actions-primary-dark);
    padding: var(--emu-common-spacing-none);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav ul li:last-child {
    border-right: none;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav ul li:last-child a {
    margin-right: 0;
  }

  .general-header .header .header__content .header__nav-wrapper .header__prenav ul li a {
    white-space: pre;
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    margin-left: 10px;
    margin-right: 10px;
    line-height: 10px;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav {
    padding-top: var(--emu-common-spacing-none);
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent {
    position: static;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent:after {
    display: none;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a span:hover {
    text-decoration: none;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent ul {
    width: 210px;
    border-top: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
    position: absolute;
    top: 43px;
    left: auto;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent ul li {
    padding: var(--emu-common-spacing-none);
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent ul li a {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
    font-family: var(--emu-semantic-font-families-heading);
    width: 100%;
    padding: 10px 30px 10px 10px;
    text-decoration: none;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent ul li:hover {
    background-color: var(--emu-common-colors-white);
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 .emu-navigation__item-parent ul li:hover a {
    color: var(--emu-semantic-colors-extra-primary-blue);
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav ul {
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav ul li {
    padding: var(--emu-common-spacing-none);
    align-items: center;
    display: flex;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav ul li a {
    padding: 11px 32px 12px 31px;
  }

  .general-header .header .header__content .header__nav-wrapper .header__nav ul li a:hover {
    text-decoration: none;
  }

  .general-header .header .header__content .header__nav-wrapper .emu-navigation__item-parent .emu-navigation__menu--level-1 li a:hover {
    background: none;
  }

  .general-header .header .header__content .header__nav-wrapper .emu-navigation__item-parent ul {
    z-index: var(--emu-common-other-z-index-modal);
    display: none;
    top: 50px;
  }

  .general-header .header .header__content .header__nav-wrapper .emu-navigation__item-parent ul li {
    padding: 10px;
  }

  .general-header .header .header__content .header__nav-wrapper .emu-navigation__item-parent:hover ul {
    flex-direction: column;
    display: flex;
  }
}

@media (min-width: 865px) {
  .general-header .header .cmp-container-header .header__content .header__nav-wrapper .header__nav .emu-navigation__menu--level-0 {
    justify-content: flex-start;
  }

  .general-header .header .cmp-container-header .header__content .header__nav-wrapper .header__prenav ul li:last-child a {
    margin-right: 0;
  }

  .general-header .header .cmp-container-header .header__content .header__nav-wrapper .header__prenav ul li a {
    margin-left: 31px;
    margin-right: 31px;
    font-size: 13px;
    line-height: 14px;
  }
}

@media (min-width: 1024px) {
  .general-header .header .cmp-container-header .header__content .header__nav-wrapper .header__prenav {
    right: 0;
  }
}

#welcomekit .header .cmp-container-header {
  max-width: var(--emu-semantic-sizing-breakpoints-large);
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  #welcomekit .header .cmp-container-header {
    margin-top: 37px;
    margin-bottom: 37px;
  }
}

#welcomekit .header .cmp-container-header .header__logo {
  width: 66.67%;
}

@media (min-width: 570px) {
  #welcomekit .header .cmp-container-header .header__logo {
    width: 41.67%;
  }
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .header__logo {
    width: auto;
    min-width: 25%;
  }
}

#welcomekit .header .cmp-container-header .header__content {
  align-self: flex-end;
  margin-left: auto;
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .button {
    display: none;
  }
}

#welcomekit .header .cmp-container-header .aaaem-button {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-primary-blue);
  width: 56px;
  background-color: #0000;
  border-radius: 5px;
  padding: 4px 12px;
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper {
  max-height: 0;
  transition: all .6s;
  top: calc(100% + 20px);
  overflow: hidden;
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper {
    max-height: none;
  }
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav {
  background-color: var(--emu-semantic-colors-extra-secondary-light-grey-1100);
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav {
    background-color: #0000;
  }
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul {
  padding-top: 10px;
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul {
    align-items: flex-end;
  }
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-primary-blue);
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li {
    padding-bottom: var(--emu-common-spacing-none);
    border-bottom: none;
  }
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a {
  color: var(--emu-semantic-colors-extra-primary-blue);
  text-align: center;
  font-family: var(--emu-semantic-font-families-extra-light);
  border: none;
  padding-top: 10px;
  line-height: 19px;
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a:hover {
  background-color: var(--emu-common-colors-transparent);
}

#welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a br {
  display: none;
}

@media (min-width: 768px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
  }

  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a br {
    display: block;
  }
}

@media (min-width: 992px) {
  #welcomekit .header .cmp-container-header .emu-navigation__content-wrapper nav ul li a {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
}

#welcomekit .header .cmp-container-header .js-toggle-on .emu-navigation__content-wrapper {
  max-height: 400px;
}

#welcomekit .header .emu-navigation__item--active {
  background-color: var(--emu-semantic-colors-none);
}

#best-practice-guide .hcp-kyb-isi__container {
  padding-top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

#best-practice-guide .hcp-kyb-isi__container .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  margin-bottom: var(--emu-common-spacing-medium);
}

#best-practice-guide .hcp-kyb-isi__container .aaaem-text p b {
  font-weight: var(--emu-semantic-font-weight-bold);
  font-family: var(--emu-semantic-font-families-mono);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

#best-practice-guide .hcp-kyb-isi__container .aaaem-text p i {
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

#best-practice-guide .hcp-kyb-isi__container .aaaem-text p a {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-extra-secondary-dark-grey-600);
  text-decoration: none;
}

@media (min-width: 1024px) {
  #best-practice-guide .hcp-kyb-isi__container {
    padding-left: 23px;
    padding-right: 23px;
  }
}

.hcp-kyb-isi__container {
  padding-top: 30px;
  padding-left: 2%;
  padding-right: 2%;
}

@media (min-width: 1024px) {
  .hcp-kyb-isi__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1054px) {
  .hcp-kyb-isi__container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.hcp-kyb-isi__container .aaaem-title h5 {
  font-size: 15px;
  line-height: 16.5px;
  font-family: var(--emu-semantic-font-families-mono);
}

.hcp-kyb-isi__container .aaaem-text {
  font: var(--emu-semantic-typography-narrow-headings-large);
  color: var(--emu-common-colors-black);
}

.hcp-kyb-isi__container .aaaem-text p {
  margin-bottom: 20px;
}

.hcp-kyb-isi__container .aaaem-text a {
  text-decoration-thickness: var(--emu-common-border-width-medium);
  text-underline-offset: 5px;
  text-decoration-color: #a2aaad;
}

.hcp-kyb-isi__container .aaaem-text b {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-bold);
}

#welcomekit .hcp-kyb-isi__container {
  padding: 40px 10px 80px;
}

#welcomekit .hcp-kyb-isi__container .aaaem-text p, #welcomekit .hcp-kyb-isi__container .aaaem-text span, #welcomekit .hcp-kyb-isi__container .aaaem-text i, #welcomekit .hcp-kyb-isi__container .aaaem-text a, #welcomekit .hcp-kyb-isi__container .aaaem-text h3 {
  margin-bottom: var(--emu-common-spacing-medium);
  font-size: var(--emu-common-font-sizes-wide-medium);
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-600);
  line-height: 1;
}

#welcomekit .hcp-kyb-isi__container .aaaem-text p sup, #welcomekit .hcp-kyb-isi__container .aaaem-text span sup, #welcomekit .hcp-kyb-isi__container .aaaem-text i sup, #welcomekit .hcp-kyb-isi__container .aaaem-text a sup, #welcomekit .hcp-kyb-isi__container .aaaem-text h3 sup {
  font-size: 11px;
  top: -4px;
}

#welcomekit .hcp-kyb-isi__container .aaaem-text a {
  text-underline-offset: 5px;
}

#welcomekit .hcp-kyb-isi__container .aaaem-text h3 {
  font-family: var(--emu-semantic-font-families-mono);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 18px;
}

#welcomekit .hcp-kyb-isi__container .aaaem-text h3 .emphasis {
  margin-bottom: 16px;
  display: block;
}

@media (min-width: 992px) {
  #welcomekit .hcp-kyb-isi__container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: var(--emu-semantic-sizing-large-tablet);
    margin-left: auto;
    margin-right: auto;
  }
}

[data-component="modal"] .modal-wrapper, [data-component="modal"] .close {
  animation-name: fadeIn;
  animation-duration: .5s;
  animation-timing-function: ease;
}

[data-component="modal"] .modal-content {
  margin: 40% auto;
  animation-name: modalAnimation;
  animation-duration: .5s;
  box-shadow: 0 5px 15px #00000080;
}

@media (min-width: 768px) {
  [data-component="modal"] .modal-content {
    max-width: 390px;
    margin-top: 25%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal-content {
    margin-top: 8%;
    margin-bottom: 30px;
  }
}

[data-component="modal"] .close {
  width: 75px;
  height: 30px;
  top: 0;
  right: 0;
}

[data-component="modal"] .close:hover, [data-component="modal"] .close:active, [data-component="modal"] .close:focus {
  opacity: 1;
}

[data-component="modal"] .close span {
  display: none;
}

@media (min-width: 768px) {
  [data-component="modal"] .close {
    width: 85px;
    height: 35px;
  }
}

@keyframes modalAnimation {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.js-modal-opened {
  height: 100vh;
  overflow: hidden;
}



.aaaem-text b {
  font-weight: var(--emu-semantic-font-weight-bold);
  font-family: var(--emu-semantic-font-families-mono);
}

.aaaem-text.text--bolder b {
  font-weight: var(--emu-common-font-weight-bold);
}

.aaaem-text h1, .aaaem-text h2, .aaaem-text h3, .aaaem-text h4, .aaaem-text h5, .aaaem-text h6, .aaaem-text p, .aaaem-text li, .aaaem-text a, .aaaem-text b, .aaaem-text i, .aaaem-text pre, .aaaem-text sup, .aaaem-text sub, .aaaem-text u, .aaaem-text pre, .aaaem-text th, .aaaem-text td, .aaaem-text span {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.aaaem-text sup, .aaaem-text sub {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.aaaem-text sup {
  top: -5px;
}

.aaaem-text sub {
  top: 5px;
}

.aaaem-title h1, .aaaem-title h2, .aaaem-title h3, .aaaem-title h4, .aaaem-title h5, .aaaem-title h6 {
  color: inherit;
}

.aaaem-title__color-primary {
  color: var(--emu-semantic-colors-extra-primary-blue);
}

.aaaem-title__color-secondary {
  color: var(--emu-semantic-colors-extra-secondary-red-100);
}

.aaaem-title__color-white {
  color: var(--emu-common-colors-white);
}

.aaaem-title__color-black {
  color: var(--emu-common-colors-black);
}

/*# sourceMappingURL=main.css.map */
