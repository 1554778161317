.accordion.panelcontainer {
  border-width: var(--emu-common-border-width-thin);
  border-top-style: solid;
  border-color: var(--emu-semantic-colors-extra-secondary-light-grey-700);

  .aaaem-accordion {
    &__button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;

      &:hover {
        opacity: 0.8;
      }
    }

    &__icon {
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: var(--emu-semantic-colors-extra-secondary-light-grey-800);
      align-self: center;
      margin-bottom: 5px;
    }

    &__title {
      padding-left: 25px;
      line-height: 19px;
      padding-bottom: 5px;
    }

    &__header.-ba-is-active {
      padding-bottom: 3px;
    }
  }

  .aaaem-accordion__panel {
    .aaaem-text {
      ul,
      li,
      p,
      a {
        color: var(--emu-semantic-colors-text-light);
      }
      ul,
      p {
        padding-left: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);
      }
    }

    &.-ba-is-active {
      border-width: var(--emu-common-border-width-thin);
      border-bottom-style: solid;
      border-color: var(--emu-semantic-colors-extra-secondary-light-grey-700);
    }
  }
}
