#about-kyb {
  .aaaem-embed__embeddable {
    padding-bottom: calc(56.25% + 58px); // from live site
  }

  // add this class to "For adults with moderate to severe" text container
  .text-container__for-adults {
    margin-top: 20px;
  }

  // add this class to "For adults with moderate to severe container", "KYBELLA® helps you serve" & "KYBELLA® injection is supplied" text component
  .image-container__bg-white {
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;

    .aem-Grid {
      .image {
        width: 100%;
        margin-left: var(--emu-common-spacing-none);
        margin-bottom: 6px;

        img {
          width: auto;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .tailored-img {
      img {
        max-width: min(100%, 399px);
      }
    }

    .one-in-three-img {
      img {
        max-width: min(100%, 422px);
      }
    }

    .injection-box-img {
      img {
        max-width: min(100%, 356px);
      }
    }
  }
}
