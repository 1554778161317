.aaaem-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  &__color {
    &-primary {
      color: var(--emu-semantic-colors-extra-primary-blue);
    }

    &-secondary {
      color: var(--emu-semantic-colors-extra-secondary-red-100);
    }

    &-white {
      color: var(--emu-common-colors-white);
    }

    &-black {
      color: var(--emu-common-colors-black);
    }
  }
}
