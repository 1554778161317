#setup-account {
  .footer-copyright-text ul li {
    &:nth-child(1) {
      display: block;
    }

    &:nth-child(2) {
      display: none;
    }
  }
}
