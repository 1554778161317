#csd-page {
  .csd {
    &-clinical__image {
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        // needed to match design
        @include mq('420px') {
          max-width: 60%;
        }

        @include mq('medium') {
          max-width: 443px;
        }
      }
    }

    &-clinical__image-two {
      background-color: var(--emu-common-colors-white);
      border-radius: 20px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;

      .aaaem-image {
        margin-left: 10px;
        img {
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            max-width: 866px;
          }
        }
      }

      .aaaem-text {
        font-size: 12px;
        line-height: 17px;

        p {
          margin-bottom: 10px;
          color: var(--emu-semantic-colors-extra-secondary-light-grey-900);
        }
      }
    }
  }

  .emu-spacing-mb__none {
    p {
      margin-bottom: var(--emu-semantic-sizing-none);
    }
  }
}
