body {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-extra-secondary-dark-grey-400);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin-top: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
}
