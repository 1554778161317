#faq-page {
  .accordion-container {
    .aem-Grid {
      max-width: 696px;
    }

    .button {
      text-align: end;

      .aaaem-button,
      .aaaem-button:hover,
      .aaaem-button:active {
        background: none;
        border: none;
        outline: none;
        color: var(--emu-semantic-colors-extra-secondary-red-100);
        letter-spacing: 1px;
        padding: var(--emu-common-spacing-none);
        margin-left: 15px;
        margin-bottom: 7px;
        font-family: var(--emu-semantic-font-families-heading);
        text-decoration: underline;
        font-weight: 700;
      }

      .aaaem-button:hover {
        text-decoration: none;
      }
    }

    .aaaem-accordion__icon {
      height: 12px;
      width: 12px;
    }
    .aaaem-accordion__icon::before,
    .aaaem-accordion__icon::after {
      height: 8px;
      top: 1px;
    }

    .faq-page-small__text {
      margin-top: 15px;
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 17px;
      margin-left: -20px; // needed, as the content must start outside padding of the panel, to match the design
    }

    .aaaem-text {
      > ul {
        > li {
          // first inner level needs — as a li list marker
          > ul {
            list-style: none;

            > li {
              position: relative;
              padding-left: 12px;
              &::before {
                content: '— ';
                position: absolute;
                left: 0;
              }

              // second inner level needs dot as a li list marker. So no change in the marker
              > ul {
                list-style: disc;
                margin-left: 26px;

                > li {
                  > ul {
                    list-style: none;
                    // third inner level needs — as a li list marker
                    > li {
                      position: relative;
                      padding-left: 12px;
                      &::before {
                        content: '— ';
                        position: absolute;
                        left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    a {
      color: #337ab7; // no var, used only once in the site
      text-decoration: none;
      u {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }

      .emphasis {
        color: var(--emu-semantic-colors-actions-primary-dark);
        display: inline-block;
        border-bottom: 1px solid transparent;
        text-decoration: underline;
      }

      &:hover {
        color: #23527c; // no var, used only once
        .emphasis {
          border-bottom-color: var(--emu-semantic-colors-actions-primary-dark);
        }
      }
    }
  }

  .footer-copyright-text ul li {
    &:nth-child(1) {
      display: block;
    }

    &:nth-child(2) {
      display: none;
    }
  }
}
